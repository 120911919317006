import FeatherIcon from 'feather-icons-react';
import IcN from '../../images/row2.jpeg';
import { useEffect, useState } from 'react';
import DashB from './DashB';
import { Link, Route, Routes } from 'react-router-dom';
import SubScriber from './SubScriber';
import Kiny from './Kiny';
import AdNews from './AdNews';
import AdBook from './AdBook';
import AdMagazine from './AdMagazine';
import AdLetter from './AdLetter';
import AdAudio from './AdAudio';
import AdSetting from './AdSetting';
import AdAds from './AdAds';
import AddKiny from './forms/AddKiny';
import AddCat from './forms/AddCat';
import AddNews from './forms/AddNews';
import AddBook from './forms/AddBook';
import AddMag from './forms/AddMag';
import AddLetter from './forms/AddLetter';
import AddText from './forms/AddText';
import AddAdvert from './forms/AddAdvert';
import AddAudio from './forms/AddAudio';

const AdNav=(p)=>{
    const [icLen,setHei]=useState(16); const icLenX=16;
    const [iC,setIc]=useState('align-right');
    const chR=()=>setIc('align-right');
    const chL=()=>setIc('align-left');
    useEffect(()=>{  
let X=document.querySelector('.UlVd'),
LiQ=document.querySelector('.AShow'),NavL=document.querySelectorAll('.NbCdc'),
Gn=document.querySelectorAll('.GxcV'),
        btnC=document.querySelector('.DsIy'),
        DivC=document.querySelector('.AdN');

  NavL=Array.from(NavL);

        function HdX(e){
    X.classList.toggle('HdN');
    if(!X.classList.contains('HdN')){
     LiQ.classList.add('ShWN');
    }else{LiQ.classList.remove('ShWN');}
    }
  

 NavL.forEach((n)=>{
     n.addEventListener('click',()=>{
        X.classList.add('HdN');
        NavL.forEach((nx)=>nx.parentElement.classList.remove('KnXA'));
        n.parentElement.classList.add('KnXA');
     })
    });
 LiQ.addEventListener('click',HdX);


      
    var State= localStorage.getItem('stMnQx') || 1;
     if(State==2){DivC.classList.add('AdAcT');setHei(22);chL()}

        btnC.addEventListener('click',()=>{
            var sx=1;
            DivC.classList.toggle('AdAcT');
            if(DivC.classList.contains('AdAcT')){sx=2;setHei(22);chL()}
            else{setHei(16);chR();}
            localStorage.setItem('stMnQx',sx);
        });

        
    },[]);
    return (
        <div className="AdN">
            <div className="adLeftM ">
                <div className='LgN'>
                    <img src={IcN} className='LgIx'/>
                    <span className='spTB'>Kinyamateka</span>
                    </div>
            <nav className='DnaV'>
                <ul>

              <li>
                  <Link to='/a/dashboard' className='NbCdc'>
                  <span className='GIcN G2'><FeatherIcon icon='grid' height={icLen} width={icLen} /></span>
                  <span  className='GxcV'>Dashboard</span>
                  </Link>
              </li>

              <li>
              <Link to='/a/subscriber' className='NbCdc'>
                  <span className='GIcN G3'><FeatherIcon icon='users' height={icLen} width={icLen} /></span>
                  <span to='subscriber' className='GxcV'>Subscribers</span>
                  </Link>
              </li>
              

              
              <li>
              <Link to='/a/kinyamateka' className='NbCdc'>
                  <span className='GIcN G4'><FeatherIcon icon='database' height={icLen} width={icLen} /></span>
                  <span className='GxcV'>Kinyamateka</span>
                  </Link>
              </li>

              <li>
              <Link to='/a/news' className='NbCdc'>
                  <span className='GIcN G5'><FeatherIcon icon='tv' height={icLen} width={icLen} /></span>
                  <span className='GxcV'>News</span>
                  </Link>
              </li>

              <li>
              <Link to='/a/books' className='NbCdc'>
                  <span className='GIcN G6'><FeatherIcon icon='book' height={icLen} width={icLen} /></span>
                  <span className='GxcV'>Books</span>
                  </Link>
              </li>
              <li>
              <Link to='/a/magazine' className='NbCdc'>
                  <span className='GIcN G7'><FeatherIcon icon='book-open' height={icLen} width={icLen} /></span>
                  <span className='GxcV'>Magazine</span>
                  </Link>
              </li>
              <li>
              <Link to='/a/letter' className='NbCdc'>
                  <span className='GIcN G8'><FeatherIcon icon='file' height={icLen} width={icLen} /></span>
                  <span className='GxcV'>Letters</span>
                  </Link>
              </li>
              <li>
              <Link to='/a/audio' className='NbCdc'>
                  <span className='GIcN G9'><FeatherIcon icon='music' height={icLen} width={icLen} /></span>
                  <span className='GxcV'>Audio</span>
              </Link>
              </li>
              <li>
              <Link to='/a/advertisment' className='NbCdc'>
                  <span className='GIcN G10'><FeatherIcon icon='radio' height={icLen} width={icLen} /></span>
                  <span className='GxcV'>Advertisment</span>
                  </Link>
              </li>

              <li>
              <Link to='/a/settings' className='NbCdc'>
                  <span className='GIcN G11'><FeatherIcon icon='settings' height={icLen} width={icLen} /></span>
                  <span className='GxcV'>Settings</span>
                  </Link>
              </li>

                </ul>
            </nav>
            </div>





            <div className="adRightF">
                <div className="RightT">
                <span className='DsIy'><FeatherIcon icon={iC}/></span>
                <div  className='LiQuick'>
                    <a className='AShow'>
                  <span className='GIcN G1'><FeatherIcon icon='plus' height={icLenX} width={icLenX} /></span>
                  <span className='GxcVXX'>Quick Links</span>
                  </a>



                  <div  className='UlVd HdN'>
                      <ul>
                      <li>
                 <Link to='/a/add-categories' className='NbCdc'>
                  <span className='GIcN G2'><FeatherIcon icon='plus' height={icLenX} width={icLenX} /></span>
                  <span  className='GxcVX'>Add Categories</span>
                </Link>              
                  </li>

                  <li>
                      <Link to='/a/add-kiny' className='NbCdc'>
                  <span className='GIcN G2'><FeatherIcon icon='plus' height={icLenX} width={icLenX} /></span>
                  <span  className='GxcVX'>Add Kinyamateka</span>
                  </Link>              
                 </li>
                 <li>
                 <Link to='/a/add-news' className='NbCdc'>
                  <span className='GIcN G2'><FeatherIcon icon='plus' height={icLenX} width={icLenX} /></span>
                  <span  className='GxcVX'>Add News</span>
                  </Link>              
                 </li>

                 <li>
                   <Link to='/a/add-book' className='NbCdc'>
                  <span className='GIcN G2'><FeatherIcon icon='plus' height={icLenX} width={icLenX} /></span>
                  <span  className='GxcVX'>Add a new book</span>
                  </Link>              
                 </li>

                 <li>
                   <Link to='/a/add-magazine' className='NbCdc'>
                  <span className='GIcN G2'><FeatherIcon icon='plus' height={icLenX} width={icLenX} /></span>
                  <span  className='GxcVX'>Add a new magazine</span>
                  </Link>              
                 </li>
                  

                </ul>
                <ul>
                      <li>
                   <Link to='/a/add-letter' className='NbCdc'>
                  <span className='GIcN G2'><FeatherIcon icon='plus' height={icLenX} width={icLenX} /></span>
                  <span  className='GxcVX'>Add a new letter</span>
                  </Link>              
                 </li>  


                 <li>
                   <Link to='/a/add-text' className='NbCdc'>
                  <span className='GIcN G2'><FeatherIcon icon='plus' height={icLenX} width={icLenX} /></span>
                  <span  className='GxcVX'>Add moving text</span>
                  </Link>              
                 </li>

                 <li>
                   <Link to='/a/add-adverts' className='NbCdc'>
                  <span className='GIcN G2'><FeatherIcon icon='plus' height={icLenX} width={icLenX} /></span>
                  <span  className='GxcVX'>Add advertisment</span>
                  </Link>              
                 </li>
                 <li>
                   <Link to='/a/add-audio' className='NbCdc'>
                  <span className='GIcN G2'><FeatherIcon icon='plus' height={icLenX} width={icLenX} /></span>
                  <span  className='GxcVX'>Add Audios</span>
                  </Link>              
                 </li>
                </ul>
                            
                  </div>

                </div>


                <div className='DcbYl'>
                <p className='PnVui'>ADMIN , FIDELLE </p>
        <div className='UserF'><FeatherIcon icon='user'/></div>
                </div>
                    </div>
                
                
                
                
                <div className="RightB">
                   <Routes>
                <Route index path='/' element={<DashB/>}></Route>
                <Route index path='/dashboard' element={<DashB/>}></Route>
                <Route path='/subscriber' element={<SubScriber/>}></Route>
                <Route path='/kinyamateka' element={<Kiny/>}></Route>
                <Route path='/News'element={<AdNews/>}></Route>
                <Route path='/books' element={<AdBook/>}></Route>
                <Route path='/magazine' element={<AdMagazine/>}></Route>
                <Route path='/letter' element={<AdLetter/>}></Route>
                <Route path='/audio' element={<AdAudio/>}></Route>
                <Route path='/advertisment' element={<AdAds/>}></Route>
                <Route path='/settings' element={<AdSetting/>}></Route>
                <Route path='/add-kiny' element={<AddKiny/>}></Route>
                <Route path='/add-categories' element={<AddCat/>}></Route>
                <Route path='/add-news' element={<AddNews/>}></Route>
                <Route path='/add-book' element={<AddBook/>}></Route>
                <Route path='/add-magazine' element={<AddMag/>}></Route>
                <Route path='/add-letter' element={<AddLetter/>}></Route>
                <Route path='/add-text' element={<AddText/>}></Route>
                <Route path='/add-adverts' element={<AddAdvert/>}></Route>
                <Route path='/add-audio' element={<AddAudio/>}></Route>

                   </Routes>
                    </div>
                
            </div>
        </div>
    )
}
export default AdNav;