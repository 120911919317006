export default function AddCat(){
    return (
        <div className="GnFrM">
          <form className="FrmGeneral">
            <h1 className="FRMtX">Add Categories</h1>
            <label className="lblL">Category Type</label>
            <select className="seLin">
              <option>News</option>
              <option>Books</option>
              <option>Letters</option>
              <option>Magazine</option>
              <option>Audio</option>
            </select>
            <label className="lblL">Category name</label>
            <input type='text' className='INpX' placeholder='' name='' id='' />
            <div className="SINP"><input type='submit' className="SubInp" value='Save'/></div> 
          </form>
          
        </div>
    )
}