import SubPlan from "./SubPlan";
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import Dashboard from "./Dashbord";
import RenewP from "./RenewP";
import TodoContainer from "../../todos/TodoContainer";
/*Account Information= <SubSec/>   */

const SubAccount=()=>{
    let nav=useNavigate();
    const [renewSub,isRenew]=useState(false);
    const [dashb,isDashboard]=useState(true);
    const [renewP,isRenewP]=useState(false);
    const showRenew=()=>{isDashboard(false);isRenewP(false);isRenew(true);}
    const ShowDashboard=()=>{isRenewP(false);isRenew(false);isDashboard(true);}
    const showRenewP=()=>{isDashboard(false);isRenew(false);isRenewP(true);}
    useEffect(()=>{
        console.log('hello');
var liX=document.querySelectorAll('.RjCKx');liX=Array.from(liX);
        liX.forEach((li)=>{
        li.onclick=(e)=>{
            liX.forEach((liz)=>{liz.classList.remove('Lhight');});
            li.classList.add('Lhight');
        }
        });
    },[]);
    const BackN=()=>{nav('/');}
    return (
        <div className="SuAxC">
            <div className="Suxleft">
            <p className="w-full text-[30px] p-2 text-center"> KINYAMATEKA </p>
                <div className="menuX">
               <ul className="NBVx">
               <li onClick={BackN}>Back to News</li>
               <li onClick={showRenew}  className="RjCKx">Renew Subscription</li>
               <li onClick={ShowDashboard} className="Lhight RjCKx">Dashboard</li> 
               <li onClick={showRenewP} className="RjCKx">Change Password</li>
               <li onClick={BackN}>Logout</li>
               </ul>
                </div>
            </div>
            <div className="SuxvRight">
             <div className="RGhTop">
             <span className="PrcN">
                 <p className="pNnmx">Tuyisabe</p>
                <span className="ProfileL">
                <FeatherIcon height='20' width='20' icon='user'/> 
                </span>
             </span>
             </div>
             <div className="RGhBdy">
                <TodoContainer/>
            {dashb && <Dashboard/>}
            {renewSub && <SubPlan/>}
            {renewP && <RenewP/>}
             </div>
            </div>
         
        </div>
    );

}
export default SubAccount;