import { useState } from "react"
import { useNavigate } from "react-router-dom";
import AdNav from "./AdNav";


export default function AdM(){
    let nav=useNavigate();
    const [Lged,isLged]=useState(true);
    if(Lged==false){nav('/Amakuru')}
    return (
        <div>
        {Lged && <AdNav/>}
        </div>
    );
}