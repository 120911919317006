import { useCallback, useEffect, useState } from "react";
import LoginContainer from "./LoginContainer";
import http from "../../config/axiousBase";

export default function ForgetPassword(p) {
  let values = {
    code: "",
    password: "",
    password2:"",
    email: "",
  };

  const [upValue, setvalues] = useState(values),
    [loading, setLoading] = useState(false),
    [success, setSuccess] = useState(false),
    [errorMessage, setErrorMessage] = useState(""),
    [error, setError] = useState(false),
    [isReset, setReset] = useState(false),
    [valid, setValid] = useState(false),
    [login, goLogin] = useState();

  const handleChange = (e) => {
    setError(false);
    setValid(false);
      const name = e.target.name,
        value = e.target.value;
      setvalues((values) => ({ ...values, [name]: value }));
  };

  /**
   * -------------------------------------------------------------------
   * Handle client reset password
   * ----------------------------------------------------------------------------
   */
  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (upValue.email === "") {
      setErrorMessage(
        "Shyiramo emeri yawe neza"
      );
      setValid(true);
      return;
    }

    try {
      setLoading(true);
      const endpoint = "api/reset_password/";
      const response = await http.post(endpoint, upValue);
      if (response) {
        setLoading(false);
        setSuccess(true);
        //redirect user to login page
        setTimeout(() => {
          setSuccess(false);
          setReset(true)
        }, 1500);
      }
    } catch (error) {
      setLoading(false);
      setError(true);
      setSuccess(false);
      console.log("error", error);
    }
  };

  /**
   * -------------------------------------------------------------------
   * Handle client reset password
   * ----------------------------------------------------------------------------
   */
  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (upValue.password.length < 8) {
      setErrorMessage(
        "Passward igomba kuba irihejuru cyagwa ingana na characters 8"
      );
      setValid(true);
      return;
    }else if (upValue.password !== upValue.password2) {
      setErrorMessage("Ijambobanga rigomba kuba risa nkiryakabiri");
      setValid(true);
      return;
    }
    try {
      setLoading(true);
      const endpoint = "api/change_password/";
      const response = await http.post(endpoint, upValue);
      if (response) {
        setLoading(false);
        setSuccess(true);
        //redirect user to login page
        setTimeout(() => {
          setSuccess(false);
          goLogin(p.lgn);
        }, 1500);
      }
    } catch (error) {
      setLoading(false);
      setError(true);
      setSuccess(false);
      console.log("error", error);
    }
  };

  return (
    <LoginContainer p={p}>
      <form className="FrmLGN">
        <div className="InCont">
          <h1 className="lgn-titl">Forget Password</h1>
        </div>
        <div className="InCont">
          <label>Email</label>
          <input
            autoComplete="off"
            id="email"
            name="email"
            onChange={handleChange}
            maxLength={40}
            type="email"
            className="form-control mb-2 p-2 mt-1"
            placeholder="Email yawe"
          />
        </div>


        {isReset && (<div className="InCont">
        <div className="InCont">
          <label>Code</label>
          <input
            autoComplete="off"
            id="code"
            name="code"
            onChange={handleChange}
            type="text"
            className="form-control mb-2 p-2 mt-1"
            placeholder="Code wakiye kuri email"
          />
        </div>
        <div className="InCont">
          <label>Ijambobanga</label>
          <input
            autoComplete="off"
            id="password"
            name="password"
            onChange={handleChange}
            type="password"
            className="form-control mb-2 p-2 mt-1"
            placeholder="Password"
          />
        </div>
        <div className="InCont">
          <label>Emeza Ijambobanga</label>
          <input
            autoComplete="off"
            id="password2"
            name="password2"
            onChange={handleChange}
            type="password"
            className="form-control mb-2 p-2 mt-1"
            placeholder="Password"
          />
        </div>
        </div>)}

        {success && (
          <div className="alert alert-success InCont" role="alert">
            Igikorwa cyagenze neza
          </div>
        )}

        {error && (
          <div className="alert alert-danger InCont" role="alert">
            NTago byakunze, wongere mukanya
          </div>
        )}

        {valid && (
          <div className="alert alert-danger InCont" role="alert">
            {errorMessage}
          </div>
        )}



        <div className="InCont">
          {!isReset && !loading && ( <button className="LGN-SUB" onClick={handleResetPassword}>Ohereza code kuri email yawe</button>)}

          {isReset && !loading && (<button className="LGN-SUB" onClick={handleChangePassword}>Hindura ijambo banga</button>)}
        </div>

        <div
            className="flex items-center justify-center w-full"
            role="status"
          >
            {loading && (
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </div>


        <div
            className="InCont pt-1"
          >
            <button
              onClick={p.lgn}
              className="BtnNS pb-4 font-light"
              type="button"
            >
              Login
            </button>
          </div>
      </form>
    </LoginContainer>
  );
}
