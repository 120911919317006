import kinyFull from "../../images/kinyFull.png";
import FeatherIcon from "feather-icons-react";


export default function LoginContainer(p) {
  let pxV = p.vl,
    cl = "divLGIMG";
  if (pxV == "sign") {
    cl = "divLGIMG2";
  }

  return (
    <div className={"loginTopx " + p.clsx}>
      <p className="pClose" onClick={p.p.lgn}>
        <FeatherIcon height="15" width="15" icon="arrow-left" />{" "}
      </p>

      <div className="LgnCont">
        <div className="lgnTP">
          <div className={cl}>
            <img src={kinyFull} className="LGlg-image"></img>
          </div>
        </div>
        {p.children}
      </div>
    </div>
  );
}
