export default function AddBook(){
    return (
      <div className="GnFrM">
      <form className="FrmGeneral">
        <h1 className="FRMtX">Register a new book</h1>

        <label className="lblL">Book Title</label>
        <input type='text' className='INpX' placeholder='' name='' id='' />
        




        <label className="lblL">Book category</label>
        <select className="seLin">
              <option>Liturujiya</option>
              <option>Isomo ry'Iyobokamana</option>
              <option>Inyigisho za Kiliziya</option>
              <option>Bikiramariya</option>
            </select>
        
        <label className="lblL">Book Author</label>
        <input type='text' className='INpX' placeholder='' name='' id='' />
        
        <label className="lblL">Book price</label>
        <input type='text' className='INpX' placeholder='' name='' id='' />


        <label className="lblL">Published Date</label>
        <input type='date' className='INpX uppercase' placeholder='' name='' id='' />

        <label className="lblL">Phone Number (Libary , etc) </label>
        <input type='text' className='INpX uppercase' placeholder='' name='' id='' />

        <label className="lblL">Address (Location)</label>
        <input type='text' className='INpX uppercase' placeholder='' name='' id='' />
        
        <label className="lblL">Cover Image</label>
        <input type='file' className='INpX' placeholder='' name='' id='' />

        <label className="lblL">Book PDF</label>
        <input type='file' className='INpX' placeholder='' name='' id='' />
         
        <label className="lblL">Book short description</label>
        <textarea className='TextAr'></textarea>
        
       
        
        <div className="SINP"><input type='submit' className="SubInp" value='Save'/></div> 
      </form>
      
    </div>
    )
}