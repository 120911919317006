export default function Pagination(p){
     function Ic(){
         let elm=[];
         for (let index =0; index<p.n;index++) {
    elm[index]=<a href="#" class="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-blue-400 hover:text-white">
          {index+1}
       </a>
             
         }
         return  elm
     }
     let elm=Ic();
     var lx=elm.map((el)=>el);

    return (
        <div class="flex mt-5 justify-center w-full items-center space-x-1">
    <a href="#" class="flex items-center px-4 py-2 text-gray-500 bg-gray-300 rounded-md">
        Previous
    </a>
    {lx}

    
    <a href="#" class="px-4 py-2 font-bold text-gray-500 bg-gray-300 rounded-md hover:bg-blue-400 hover:text-white">
        Next
    </a>
</div>
    )
}