import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
//<NoContent value='Nta matangazo abashije kuboneka'/>

export default function AmatangazoLeft(p) {
  const [amatangazoL, setAmatangazo] = useState([]),
    limit = typeof p.limit === "undefined" || p.limit === null ? 50 : p.limit;
  let _linkx = p.type,
    typx = typeof p.ty === "undefined" ? "amatangazo" : p.ty.link,
    _typx3 = typeof p.type3 === "undefined" ? "" : p.type3.link;
  typx = p.type2 == "sub" ? _typx3 : typx;
  useEffect(() => {
    const showAmatangazo = (ty) => {
      let nMsd = ty.map((v, i) => {
        let _Link = "/" + typx + "/" + window.btoa("News#" + v.id + "#abdu");
        if (i < limit) {
          return (
            <Link className="amaVgtEr" key={i} state={v} to={_Link}>
              <li>
                <p className="pFeatAma">
                  <FeatherIcon height="16" width="16" icon="arrow-right" />
                </p>
                <p>{v.title}</p>
              </li>
            </Link>
          );
        }
      });
      return nMsd;
    };
    setAmatangazo(showAmatangazo([{}, {}, {}]));
    fetch(p._lk + _linkx + "/", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((ty) => {
        let nm = showAmatangazo(ty);
        setAmatangazo(nm);
      });
    return () => {};
    var lists = document.querySelector(".amaVgtEr");
    lists = Array.from(lists);
    lists.forEach((element) => {});
  }, [p.title]);

  return (
    <div className="_hhY3232 w-full">
      <p className="_JKnhft">{p.title}</p>
      <ul className="ulAmatangazoX">{amatangazoL}</ul>
    </div>
  );
}
