import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import InfoCard from "./pageComp/InfoCard";
import NewsLeft1 from "./pageComp/NewsLeft1";
import NewsSocial from "./pageComp/NewsSocial";
import AmatangazoLeft from "./AmatangazoLeft";
import KinyOld from "./KinyOld";
export default function News(p) {
  const _lk = p._lk,
    _home = { id: 0, _type: "menu", title: "Amakuru", link: "amakuru" };
  const allM = typeof p.allM === "undefined" ? [] : p.allM;
  let menuss = [],
    i = 0;
  allM.forEach((mn) => {
    if (mn.id > 0 && mn.id <= 5) {
      mn._type = "menu";
      menuss[i] = (
        <Route
          key={mn.id}
          path={"/" + mn.link + "/*"}
          element={<NewsLeft1 ads={p.ads} ty={mn} _lk={_lk} />}
        ></Route>
      );
      mn.submenu_menu.forEach((subM) => {
        i++;
        var newM = JSON.parse(JSON.stringify(mn));
        newM._type = "subM";
        menuss[i] = (
          <Route
            key={mn.id + subM.id}
            path={"/" + subM.link + "/*"}
            element={<NewsLeft1 ty={newM} ads={p.ads} subM={subM} _lk={_lk} />}
          ></Route>
        );
      });
      i++;
    }
  });

  const [kinya, setKinya] = useState([{}, {}, {}]);
  useEffect(() => {
    var thisDate = new Date();
    thisDate = thisDate.getFullYear();
    fetch("https://kinyamateka.rw/api/kinyamatekalistdate/" + thisDate, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((ty) => {
        setKinya(ty);
      })
      .catch((er) => {});
  }, []);

  const showKinyP = (kiny) => {
    var subS = localStorage.getItem("_kiny_");
    subS = subS == null ? 1 : JSON.parse(subS);
    subS = subS == 1 ? subS : subS.sub.length == 0 ? 1 : subS.sub[0].plan;
    if (subS >= kiny.plan) {
      p.showKiny(true);
      p.kinyPdf(kiny);
    } else {
      p.setNewsP(kiny.plan);
      p.subDv();
    }
  };

  //kinya[0].cover
  //kinya[0].bottom_title
  return (
    <div className="news-dv">
      <div className="ArrLeft">
        <div className="Nleft">
          <Routes>
            <Route
              path="/"
              index
              element={
                <NewsLeft1
                  ptext={p.ptext}
                  ads={p.ads}
                  ty={_home}
                  allM={allM}
                  _lk={_lk}
                />
              }
            ></Route>
            <Route
              path={"/" + _home.link + "/*"}
              element={
                <NewsLeft1
                  ptext={p.ptext}
                  ads={p.ads}
                  allM={allM}
                  ty={_home}
                  _lk={_lk}
                />
              }
            ></Route>
            {menuss}
          </Routes>
        </div>
        <div className="NRight">
          <div className="kinyNew bg-slate-600 sLgf">
            <div className="KinyTop">
              <img src={kinya[0]?.cover} className="KinyImg iMgTop" />
            </div>
            <div
              onClick={(e) => {
                showKinyP(kinya[0]);
              }}
              className="KinyBtM"
            >
              {kinya[0]?.bottom_title}
            </div>
          </div>

          <div className="kinyOld sLgf">
            <div className="_prev_ F-iconsX fx1">
              <FeatherIcon height="30" width="30" icon="arrow-left" />
            </div>
            <div className="_next_ F-iconsX fx2">
              <FeatherIcon height="30" width="30" icon="arrow-right" />
            </div>
            <KinyOld
              showKinyP={showKinyP}
              _lk={p._lk}
              showKiny={p.showKiny}
              kinyPdf={p.kinyPdf}
            />
          </div>

          <div className="_AmatangazoDv sLgf">
            <p className="_mnyHNgt mb-2"></p>
            <AmatangazoLeft
              _lk={p._lk}
              type="amatangazo-list"
              title={"Amatangazo mashya"}
              ty={allM[5]}
              limit="15"
            />
          </div>

          <NewsSocial />
        </div>
      </div>
    </div>
  );
}
