export default function RenewP(){
    return (
        <div className="RenewP">
            <form className="FrmXC">
                <h1>Hindura ijambo ry'ibanga</h1>
            <label>Ijambo ryibanga rya kera</label>
            <input type='password'maxLength={20} className="inputX" placeholder='******' />
            <label>Ijambo ryibanga rishya</label>
            <input type='password' maxLength={20} className="inputX" placeholder='******' />
            <label>Emeza ijambo ryibanga rishya</label>
            <input type='password' maxLength={20} className="inputX" placeholder='******' />
            <span className="submx"><input type='submit' value='Hindura' className="suBxx" /></span>
            </form>
            
            </div>
    );

}