import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
export default function AmatangazoRight(p) {
  const location = useLocation(),
    { ita_id } = useParams();
  let data = location.state;
  data = data === null ? {} : data;
  let linkxX = p.type;
  const [showDta, setShowData] = useState([]);
  function showDatas(dts) {
    let html = dts.map((d, i) => {
      return (
        <div key={i} className="itangazo">
          <div className="amTdvTirs">{d.description}</div>
          <a
            href={d.file_document ? d.file_document : d.file}
            target="_blank"
            rel="noopener"
            className="text-primary"
          >
            kanda hano kureba ibaruwa
          </a>
        </div>
      );
    });
    setShowData(html);
  }
  useEffect(() => {
    if (Object.keys(data).length == 0) {
      var linkI = window.atob(ita_id);
      linkI = linkI.split("#")[1];
      fetch(p._lk + linkxX + "/" + linkI, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((ty) => {
          showDatas(ty);
        });
    } else {
      showDatas([data]);
    }
  }, [ita_id]);

  useEffect(() => {
    var elem = document.querySelector(".imgXfUl");
    if (elem !== null) {
      var parent = elem.parentElement;
      parent.classList.add("imgXDiv2");
      elem.classList.add("hidden");
      elem.src = elem.dataset.src;
      elem.onload = () => {
        elem.classList.remove("hidden");
        parent.classList.remove("imgXDiv2");
      };
      elem.onerror = () => {
        parent.classList.remove("imgXDiv2");
      };
    }
  });

  return <div className="w-full">{showDta}</div>;
}
