import ChooseSubs from "./ChooseSubs";
import SubPayment from "./SubPayment";
import { useCallback, useEffect, useState } from "react";

export default function AlertSubs(p) {
  const [hide, setHide] = useState();

  useEffect(() => {
    setHide(p.hideSub);
  }, []);

  return (
    <div className="alertSub hidden">
      <div className="alertOverlay"></div>
      <div className="alerSubDiv">
        <div className="_subClose" onClick={p.hideSub}>
          X
        </div>
        <div className="_chsSubs">
          Ikaze ku rubuga rwa Kinyamateka. Hitamo ifatabuguzi rikubereye
          kugirango ukomeze kubona amakuru agezweho no gusoma kinyamateka mu
          ikoranabuhanga rigezweho. Tugushimiye uruhare rwawe mu kubaka
          Kinyamateka ibereye Kiliziya yacu. Imana iguhe umugisha.
        </div>
        <div className="chosSubsPln p-5 mt-3">
          <ChooseSubs newsP={p.newsP} ht={true} />
          <SubPayment />
        </div>
      </div>
    </div>
  );
}
