export default function AddAudio(){
    return (
      
      <div className="GnFrM">
      <form className="FrmGeneral">
        <h1 className="FRMtX">Add Audio</h1>

        <label className="lblL">Audio name (Optional)</label>
        <input type='text' className='INpX' placeholder='' name='' id='' />
      


        <label className="lblL">Audio category</label>
        <select className="seLin">
              <option>Gusenga</option>
              <option>Muzika Gatolika</option>
              <option>Ibiganiro</option>
            </select>
        

        <label className="lblL">Recording Date (Optional)</label>
        <input type='date' className='INpX uppercase' placeholder='' name='' id='' />
        
        <label className="lblL">Audio (.mp3 ,.M4A , .flac , .wav)</label>
        <input type='file' className='INpX' placeholder='' name='' id='' />
       
        
        <div className="SINP"><input type='submit' className="SubInp" value='Save'/></div> 
      </form>
      
    </div>
    )
}