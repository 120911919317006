import FeatherIcon from 'feather-icons-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import img from '../../images/pope.jpg';
export default function NewsVertical(p){
    const datas=p.data;
    let topNews=p.news,share=p.share;

    const convertDate=(date)=>{
      var datex=new Date(date).getTime(),todayDate=new Date().getTime(),
      tDf=todayDate-datex,tM='Second';
      let dateM=tDf/1000;
      if(dateM>=60 && tM=='Second'){dateM=dateM/(60);tM='Minute';}
      if(dateM>=60 && tM=='Minute'){dateM=dateM/(60);tM='Hour'}
      if(dateM>=24 && tM=='Hour'){dateM=dateM/24;tM='Day'}
      if(dateM>=30 && tM=='Day'){dateM=dateM/(365.5/12);tM='Month'}
      if(dateM>=12 && tM=='Month'){dateM=dateM/12;tM='Year';}
      dateM= Math.round(dateM);
       return (dateM>1) ? dateM+' '+tM+'s'+' Ago' : dateM+' '+tM+' Ago'; 
  
    } 
    let topP=topNews.map((news,i)=>{
    let Ntitle,Xc=0;
    if(Object.keys(news).length>0){
      Ntitle=news.title.split('')
     if(Ntitle.length>80){Ntitle=Ntitle.slice(0,80);Xc=1;}
     Ntitle=Ntitle.join('');
    }
     if(Xc==1){Ntitle=Ntitle+"...";}
      let _Link='/'+datas.link+'/'+window.btoa("News#"+news.id+"#abdu");
      let fbShare=share.facebook+share.base_Link+'#'+_Link,
     twitterShare=share.twitter+encodeURIComponent(share.base_Link+'#'+_Link)
      +'&&text='+encodeURIComponent(news.summary),
     whattapp=share.whattapp+encodeURIComponent(news.summary+" "+share.base_Link+'#'+_Link);
     
       if(i>0 && i<=3){
        return <div className="SliderX Svertical">
        <div className="NewStop">
        <Link to={_Link} state={news} href='#' key={news.id}>
    <img src={news.main_photo} className='NewsIMG'/></Link>
       </div>
        <div className="NewsBottom px-1 pt-1 ">
            <div className="NewTitle text-left text-[13px] font-medium">
            <Link to={_Link} state={news} href='#' key={news.id}><p>{Ntitle}</p></Link>
            <span className='NbytC'>
            <span className='HdXV'>{convertDate(news.created_timestamp)}</span>
            <a href={twitterShare} target='_blank' className='HdXV'><FeatherIcon icon='twitter'  height='14' width='14'/></a>
       <a href={fbShare} target='_blank' className='HdXV'><FeatherIcon icon='facebook' height='14' width='14'/></a>
         </span>
            </div>
          </div>
      </div>
      
      
    }

    });

    
    
    return (
        <div className='Nbydksksh'>
         {topP}
      </div>
    )
}