import Pagination from "./Pagination";

export default function AdMagazine(){
    return (
        <div className="KinYX w-[90%] relative left-[5%]">
        <p className="w-full p-5 text-[20px] font-semibold">Magazine</p>
        <table className="w-full max-w-full">
                   <thead class="bg-gray-50">
                       <tr>
                           
                           <th class="px-6 py-2 text-xs text-gray-500">
                               ID
                           </th>
                           <th class="px-6 py-2 text-xs text-gray-500">
                             # Name
                           </th>
                           <th class="px-6 py-2 text-xs text-gray-500">
                             # Category
                           </th>
                           
                           <th class="px-6 py-2 text-xs text-gray-500">
                              # Released Date
                           </th>
                           <th class="px-6 py-2 text-xs text-gray-500">
                             #  Edit
                           </th>
                           <th class="px-6 py-2 text-xs text-gray-500">
                             #  Delete
                           </th>
                           <th class="px-6 py-2 text-xs text-gray-500">
                           
                           </th>
                       </tr>
                   </thead>
                   <tbody class="bg-white">
                       <tr class="whitespace-nowrap">
                           <td class="px-6 py-4 text-sm text-gray-500">
                               1
                           </td>
                           <td class="px-6 py-4">
                               <div class="text-sm text-gray-900">
                               Hobe Numero 897
                               </div>
                           </td>
                           <td class="px-6 py-4">
                               <div class="text-sm text-gray-500">Hobe</div>
                           </td>
                           <td class="px-6 py-4 text-sm text-gray-500">
                           01/05/2021
                           </td>
                           
                           <td class="px-6 py-4 text-center">
                               <a href="#" class="px-4 py-1 text-sm text-white bg-blue-600 rounded">Preview</a>
                           </td>
                           <td class="px-6 py-4 text-center">
                               <a href="#" class="px-4 py-1 text-sm text-white bg-red-500 rounded">Delete</a>
                           </td>

                           <td>
                           <div class="form-check">
      <input class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 my-1 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
       type="checkbox" value="" id="flexCheckChecked3" checked />
    </div>
                           </td>
                       </tr>
                
                       <tr class="whitespace-nowrap">
                           <td class="px-6 py-4 text-sm text-gray-500">
                               2
                           </td>
                           <td class="px-6 py-4">
                               <div class="text-sm text-gray-900">
                               Urubuga rw’abagore
                               </div>
                           </td>
                           <td class="px-6 py-4">
                               <div class="text-sm text-gray-500">Urubuga rw’abagore</div>
                           </td>
                           <td class="px-6 py-4 text-sm text-gray-500">
                           01/05/2022
                           </td>
                           
                           <td class="px-6 py-4 text-center">
                               <a href="#" class="px-4 py-1 text-sm text-white bg-blue-600 rounded">Preview</a>
                           </td>
                           <td class="px-6 py-4 text-center">
                               <a href="#" class="px-4 py-1 text-sm text-white bg-red-500 rounded">Delete</a>
                           </td>

                           <td>
                           <div class="form-check">
      <input class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 my-1 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
       type="checkbox" value="" id="flexCheckChecked3" checked />
    </div>
                           </td>
                       </tr>
                       </tbody>

                  
               </table>
               <Pagination n={1}/>
       </div>
    )
}