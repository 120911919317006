import ChooseSubs from "./ChooseSubs";
import LoginContainer from "./LoginContainer";
import SubPayment from "./SubPayment";

export default function Subscriberon(p){
    return (
        <LoginContainer clsx='clxSII' p={p}>
            <ChooseSubs/>
            <div className="SubPay">
                <SubPayment/>
            </div>
        </LoginContainer>
    )
}