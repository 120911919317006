export default function AddKiny(){
    return (
         <div className="GnFrM">
          <form className="FrmGeneral">
            <h1 className="FRMtX">Register a new Kinyamateka</h1>

            <label className="lblL">Kinyamateka Number</label>
            <input type='text' className='INpX' placeholder='' name='' id='' />

            <label className="lblL">Date (Month/Year)</label>
            <input type='month' className='INpX uppercase' placeholder='' name='' id='' />
            
            <label className="lblL">PDF / Scanned Photos</label>
            <input type='file' className='INpX' placeholder='' name='' id='' />
             
            <label className="lblL">Bottom Title</label>
            <textarea className='TextAr'></textarea>
            
           
            
            <div className="SINP"><input type='submit' className="SubInp" value='Save'/></div> 
          </form>
          
        </div>
    )
}