import {React, useEffect } from "react";
export default function NewsSocial() {
  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.src = "//platform.twitter.com/widgets.js";
    script.setAttribute("charset", "utf-8");
    document.getElementsByClassName("twitter-embed")[0].appendChild(script);
  }, []);
  return (
    <div className="">
      <div className="mb-10">
        <div className="twitterCont">
          <section className="twitterContainer">
            <div className="twitter-embed">
              <a
                className="twitter-timeline"
                href="//twitter.com/Kinyamateka_KM?ref_src=twsrc%5Etfw"
              >
                Tweets by Kinyamateka_KM
              </a>
            </div>
          </section>
        </div>
      </div>

      <div className="">
        <div className="">
          <iframe
            width="100%"
            height="250px"
            src="https://www.youtube.com/embed/mD9QuTkLiwY"
            title="Kinyamateka"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}
