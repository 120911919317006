import { useEffect } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import Inkuru from "../Inkuru";
import NewsLeft from "./NewsLeft";
export default function NewsLeft1(p) {
  const menu_ = p.ty,
    subM_ = p.subM,
    _lk = p._lk;
  const allM = typeof p.allM === "undefined" ? [] : p.allM;
  const ads = typeof p.ads === "undefined" ? [] : p.ads;
  const bottomA = ads.filter((as) => as.location == "bottom");
  const _shareLink = {
    facebook: "https://www.facebook.com/sharer/sharer.php?u=",
    base_Link: "https://kinyamateka.rw/api/",
    twitter: "https://twitter.com/intent/tweet?url=",
    whattapp: "https://wa.me/?text=",
  };
  useEffect(() => {
    var img = document.querySelector(".imhfSS");
    if (img === null) {
    } else {
      img.src = img.dataset.src;
      img.onload = () => {
        img.parentElement.classList.remove("VdfrXS");
        img.classList.remove("invisible");
      };
      img.onerror = () => {
        img.classList.add("invisible");
      };
    }
  }, [ads]);

  return (
    <div className="newsXl1 w-full h-full">
      <Routes>
        <Route
          path="/"
          index
          element={
            <NewsLeft
              share={_shareLink}
              ptext={p.ptext}
              ads={p.ads}
              subM={subM_}
              ty={menu_}
              allM={allM}
              _lk={_lk}
            />
          }
        ></Route>
        <Route
          path="/:inkuru_id"
          element={<Inkuru share={_shareLink} ptext={p.ptext} _lk={_lk} />}
        ></Route>
      </Routes>
      <div className="_AdvertSmX1 _adsxss">
        {bottomA.length > 0 ? (
          <img
            data-src={bottomA[0].file_document}
            className="imgXXXVV imhfSS"
          />
        ) : (
          p.ptext
        )}
      </div>
    </div>
  );
}
