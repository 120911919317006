export default function AddLetter(){
    return (
      <div className="GnFrM">
      <form className="FrmGeneral">
        <h1 className="FRMtX">Add a Letter</h1>

        <label className="lblL">Letter title</label>
        <input type='text' className='INpX' placeholder='' name='' id='' />
        



        <label className="lblL">Letter category</label>
        <select className="seLin">
              <option>Amabaruwa ya PAPA</option>
              <option>Inama y’abepisikopi mu Rwanda</option>
              <option>Amaburuwa y’abepisikopi</option>
            </select>
        

        <label className="lblL">Written Date</label>
        <input type='date' className='INpX uppercase' placeholder='' name='' id='' />
        
        <label className="lblL">Letter Document , PDF</label>
        <input type='file' className='INpX' placeholder='' name='' id='' />
       
        
        <div className="SINP"><input type='submit' className="SubInp" value='Save'/></div> 
      </form>
      
    </div>
    )
}