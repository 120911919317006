import FeatherIcon from 'feather-icons-react';
import LoginContainer from "./LoginContainer";
import { useNavigate } from 'react-router-dom';
const Login = (p) => {
  let nav=useNavigate();
 function lgN(e){
  e.preventDefault();
   nav('/a');
 }

  return (
   
    <LoginContainer p={p}>
      <form className="FrmLGN" onSubmit={lgN}>
      <div className="InCont">
      <h1 className="lgn-titl">
          Administrator Login</h1>
      </div>
        <div className="InCont">
           
          <input
            autoComplete="off"
            id="email"
            name="email"
            maxLength={40}
            type="text"
            className="LGN-UserN peer"
            placeholder="Email address"
          />
          <FeatherIcon height='15' width='15' icon="phone" className='LGN-user' />
          
          
          <label
            htmlFor="email"
            className="Lbl-UserN peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
          >
           Phone Number / Email address
          </label>
        </div>
        <div className="InCont">
          <input
            autoComplete="off"
            id="password"
            name="password"
            type="password"
            maxLength={16}
            className="LGN-Pwd peer"
            placeholder="Password"
          />
          <FeatherIcon height='15' width='15' icon="lock" className='LGN-user' />
          <label
            htmlFor="password"
            className="Lbl-pwd peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm "
          >
            Password
          </label>
        </div>
        <div className="InCont">

          <button className="LGN-SUB" react>
            Sign in
          </button>
        </div>
      </form>
 </LoginContainer>
  );
};
export default Login;
