import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import InfoCard from './pageComp/InfoCard';
import InkuruLeft from './pageComp/InkuruLeft';
import NewsLeft from './pageComp/NewsLeft';
import NewsSocial from './pageComp/NewsSocial';

export default function (props){
  var url=document.location.href,_lk=props._lk;
  const location=useLocation(),{inkuru_id} =useParams();
  let data=location.state;data=(data===null) ? {} : data;
  const [dtsI,setDta]=useState(data);
  useEffect(()=>{
    var linkI=window.atob(inkuru_id);linkI=linkI.split('#')[1];
    if(Object.keys(dtsI).length==0){
      fetch(_lk+'news-details/'+linkI+'/',{
        method:'GET',headers:{'Content-Type': 'application/json'}
    }).then(res=>res.json()).then((ty)=>{if(ty.length>0){setDta(ty[0])}else{setDta(0)}});
    }
  },[inkuru_id])
    
    return  <div className="NxLfrX flex justify-center ">
      {Object.keys(dtsI).length>0 && 
      <div className="w-[85%]">
      <InkuruLeft ptext={props.ptext} data={dtsI} share={props.share}/>
   </div>

      }

        
       
       </div>
      
   
       
    
}