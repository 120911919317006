export default function AddText(){
    return (
      <div className="GnFrM">
      <form className="FrmGeneral">
        <h1 className="FRMtX">Add home moving text</h1>
       
        <label className="lblL">Texts</label>
        <textarea className='TextAr'></textarea>

        <div className="SINP"><input type='submit' className="SubInp" value='Save'/></div> 
      </form>
      
    </div>
    )
}