import { useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import WhattappIcon from './WhattappIcon';
import { Helmet } from 'react-helmet';
import Subscriberon from '../../comps/public/Subscriberon';
import ChooseSubs from '../../comps/public/ChooseSubs';
import SubPayment from '../../comps/public/SubPayment';
export default function InkuruLeft(p){
    const data=p.data;
    const [paragr,setparagr]=useState();
    const [UPlan,setUplan]=useState(1);
    let share=p.share;
    let fbShare=share.facebook+window.location.href,
twitterShare=share.twitter+encodeURIComponent(window.location.href)
 +'&&text='+encodeURIComponent(data.title),
whattapp=share.whattapp+encodeURIComponent(data.title+" "+window.location.href);
 

    let prNews=data.paragraph_news.map((pr)=>{
        let media_p=data.media_news.filter(obj=>obj.paragraph_id==pr.id);
      let dvs=media_p.map((pm)=>{return <div className="TitleImage mt-3">
          <img src={pm.file_content} className='ImgXs'/>
           <p className='text-slate-600'>{pm.title}</p>
          </div>})
        return <div key={pr.id} className='Nparagr'>
            <p>{pr.content}</p>
            {(media_p.length>0) && dvs}
            </div>
    });
    

    const [playIcn,setiCN]=useState('play');
    useEffect(()=>{
        function play(){
            var audio=document.querySelector('.audio_CC'),
            audioX2=document.querySelector('.XccX');
            if(audio.paused){audio.play();setiCN('pause');
            }else{audio.pause();setiCN('play');
            }
        }
        var audioX=document.querySelector('._audioXXX');
        if(audioX!=null){
        audioX.addEventListener('click',play)
        return ()=>{audioX.removeEventListener('click',play)}
    }

    },[])
    useEffect(()=>{
    var subS=localStorage.getItem('_kiny_');
    subS= (subS == null) ? 1 : JSON.parse(subS);
    subS= (subS==1) ? subS : subS.sub.length==0 ? 1 :subS.sub[0].plan;
    setUplan(subS);
    })
    
    return (
        <div className='w-full'>
            <Helmet>
                <title>{data.title}</title>
                <meta  property="og:title" content={data.title} />
                <meta  property="og:image" content={data.main_photo} />
                <meta  property="og:url" content={window.location.href} />
                <meta property="og:description" content={data.summary} />
            </Helmet>
            <div className="Newsx">
    <p className="NpT">{data.title}</p>
    <div className='PWriter'>
        <div className='w-full'>{"Na "+data.author +" , "+data.reg_date}</div>
        {(data.audio_news.length>0 && (data.plan=='' || UPlan>=data.plan)) &&
        <div className='divAuxXD w-full'>
    <span className="_audioXXX"><FeatherIcon className='XccX' icon={playIcn}/></span>
    <audio className='audio_CC' src={data.audio_news[0].file}></audio>
        </div> 
        }
     <div className='social-Md ActinDVX  -mt-[10px]'>
 <a target='_blank' href={fbShare} className='SocXl scxl fcBk'><FeatherIcon height='14' width='14' icon='facebook'/></a>
 <a target='_blank' href={twitterShare} className='SocXl tWITTE'><FeatherIcon height='14' width='14' icon='twitter'/></a>
 <a target='_blank' href={whattapp} data-action="share/whatsapp/share"  className='SocXl whatps'><WhattappIcon height='14px' width='14px' fill='white' /></a>
 
     </div>
    
        </div>
    <div className="TitleImage">
    <img src={data.main_photo} className='ImgXs'/>
    </div>
    <div className='News-Summary'>
    <p>{data.summary}</p> 
    </div>
    {(data.plan=='' || UPlan>=data.plan) ? <div className='News-body mb-7'>{prNews}</div>
    :<div className='divBnotx'>
        <p className='dvBY'>
        Gura Ifatabuguzi kugirango ubashe kureba ibikurikiraho kuri iyi nkuru
        </p>
        <ChooseSubs newsP={data.plan} hdF={true} ht={true}/>
        <SubPayment/>
        </div>}
   

            </div>
            </div>
    )
}