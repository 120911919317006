export default function SubPayment(p) {
  var pay2 = [
    "Kanda *183*534*535*1 kuri telephone muri banki ya equity",
    "Hamagara Numero 07838384 nurangiza kwishyura",
  ];
  return (
    <div className="subPayment">
      <p className="pyTitl">Uburyo bwo kwishyura</p>
      <ul className="subpyLi">
        <li className="mainLxp">
          <p className="title_sUBPay">Uburyo bwa mbere</p>
          <ul className="ulMidle">
            <li>
              <span>
                Ishyura unyuze kuri konti Numero <b>00040-00202247-35</b> ya
                Journal Kinyamateka iri muri <b>BK</b>
              </span>
            </li>
            <li>
              <span>
                Umaze kwishyura Ohereza inyemezabwishyu kuri whatsapp numero{" "}
                <b>0781157393</b>
              </span>
            </li>
            <li>
              <span>
                Tegereza igihe gito kugira ngo ifatabuguzi ryawe ryemezwe
              </span>
            </li>
          </ul>
        </li>
        <li className="mainLxp">
          <p className="title_sUBPay">Uburyo bwa kabiri</p>
          <ul className="ulMidle">
            <li>
              <span>
                Ishyura kuri <b>Mobile Monney</b> numero <b>0783462125</b>
              </span>
            </li>
            <li>
              <span>
                Ohereza ubutumwa bugufi kuri numero <b>0781157393</b> umenyeshe
                ko wishyuye kugira ngo ifatabuguzi ryawe ryemezwe
              </span>
            </li>
            <li>
              <span>
                Tegereza igihe gito kugira ngo ifatabuguzi ryawe ryemezwe
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}
