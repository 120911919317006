import kinyFull from "../../images/kinyFull.png";
export default function HomeLogoAdvert(p) {
  let ads = p.ads;
  let adsTop = ads.filter((as) => as.location == "head");
  return (
    <div className="d-flex justfy-content-between">
      <div className="KinYlGo m-2">
        <img src={kinyFull} className=""></img>
      </div>

      <div className="ads_img_a">
        {adsTop.length > 0 && (
          <img
            className="ads_img"
            src={adsTop[0].file_document}
          ></img>
        )}
        {adsTop.length == 0 && p.ptext}
      </div>
    </div>
  );
}


