import { useCallback, useEffect, useState,useRef } from "react"
import {Link, Route, Routes } from "react-router-dom";
import FeatherIcon from 'feather-icons-react'
import NoContent from "../../pages/pageComp/NoContent";
import AmatangazoRight from "./AmatangazoRight";
import AmatangazoLeft from "../../pages/AmatangazoLeft";
//<NoContent value='Nta matangazo abashije kuboneka'/>
 
export default function Amatangazo(p){
    let type=(p.type=='am') ? 'amabaruwa-list' : 'amatangazo-list',
    typx=(p.type=='am') ? 'amabaruwa-details' :'amatangazo-details',
    _title=(p.type=='am') ? 'Amabaruwa ':'Amatangazo'; 
    return (
   <div className="div_bNyx _amatangazoT">
    <div className="_hjHury34">
    <div className="_titNyt2">
        <Routes>
     <Route path="/:ita_id" element={
     <AmatangazoRight _lk={p._lk}  type2={p.tx}  type={typx} type3={p.subM} ty={p.ty}/>}></Route>
        </Routes>
    </div>
    <div className="_hhY323">
        <AmatangazoLeft _lk={p._lk} type2={p.tx} type3={p.subM} type={type} title={_title} ty={p.ty} limit='1000' />
    </div>
    </div>
   </div>
    );

}