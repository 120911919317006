import { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import WhattappIcon from "../../pages/pageComp/WhattappIcon";
import http from "../../config/axiousBase";

export default function Footer(p) {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  let year = new Date();
  year = year.getFullYear();
  var kinyInfo = { tel: "+250 781 157 393", email: "ikinyamateka@gmail.com" };

  let values = {
    email: "",
  };
  const [upValue, setvalues] = useState(values),
    [loading, setLoading] = useState(false),
    [success, setSuccess] = useState(false),
    [errorMessage, setErrorMessage] = useState(""),
    [error, setError] = useState(false),
    /**
     * -------------------------------------------------------------------
     * Handle user inputs change
     * ----------------------------------------------------------------------------
     */
    handleChange = (e) => {
      setError(false);
      const name = e.target.name,
        value = e.target.value;
      setvalues((values) => ({ ...values, [name]: value }));
    },
    handleEmailValidation = (email) => {
      const regex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (!email || regex.test(email) === false) {
        return false;
      }
      return true;
    },
    /**
     * -------------------------------------------------------------------
     * User registration
     * ----------------------------------------------------------------------------
     */
    handleSubmit = async (e) => {
      e.preventDefault();
      setError(false);

      if (!handleEmailValidation(upValue.email)) {
        console.log(upValue.email);
        setErrorMessage("Emeri yawe ntago yemewe");
        setError(true);
        return;
      }
      try {
        setLoading(true);
        const endpoint = "email_subscription/";
        const response = await http.post(endpoint, upValue);
        if (response) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 1500);
        }
      } catch (error) {
        setLoading(false);
        setErrorMessage("Ntago byakunze wongere mukanya");
        setError(true);
        console.log("error", error);
      }
    };

  const twitter = "//mobile.twitter.com/Kinyamateka_KM",
    youtube = "//www.youtube.com/@JOURNALKINYAMATEKA",
    facebook = "//www.facebook.com/Journalkinyamateka1",
    whattapp = "https://web.whatsapp.com/";

  let len = Math.round(p.menus.length / 2),
    lis1,
    lis2;
  lis1 = p.menus.map((menu, i) => {
    if (i <= len - 2) {
      if (menu.id == 7 || menu.id == 8) {
      } else {
        var titlex = menu.title
          .split(" ")
          .join("-")
          .toLowerCase()
          .split("'")
          .join("");
        return (
          <li key={menu.id}>
            <Link onClick={scrollToTop} to={"/" + titlex}>
              <p>{menu.title}</p>
            </Link>
          </li>
        );
      }
    }
  });

  lis2 = p.menus.map((menu, i) => {
    if (i > len - 2) {
      if (menu.id == 7 || menu.id == 8) {
      } else {
        var titlex = menu.title
          .split(" ")
          .join("-")
          .toLowerCase()
          .split("'")
          .join("");
        return (
          <li key={menu.id}>
            <Link onClick={scrollToTop} to={"/" + titlex}>
              <p>{menu.title}</p>
            </Link>
          </li>
        );
      }
    }
  });
  //<button className='SubscribeBtN'>Subscribe</button>
  //<button  className='LGNbTn'>Login</button>

  return (
    <div className="MainFooter">
      <div className="twitter"></div>

      <div className="footerLeft Fdiv">
        <div className="leftUl">
          <ul className="fUL">{lis1}</ul>
          <ul className="fUL">
            {lis2}
            <li>Privacy Policy</li>
            <li>Cookies</li>
          </ul>
        </div>
      </div>
      <div className="FCnter Fdiv">
        <p className="texc KinyTxt">KINYAMATEKA</p>
        <p className="texc">Kigali -Rwanda</p>
        <p className="texc">{kinyInfo.tel}</p>
        <p className="texc">{kinyInfo.email}</p>
        <div className="FcICont">
          <span className="fcIcX wh">
            <a target="_blank" href={whattapp}>
              <WhattappIcon fill="white" />
            </a>
          </span>
          <span className="fcIcX fb">
            <a href={facebook} target="_blank">
              <FeatherIcon height="20" width="20" icon="facebook"></FeatherIcon>
            </a>
          </span>
          <span className="fcIcX tw">
            <a href={twitter} target="_blank">
              <FeatherIcon height="20" width="20" icon="twitter"></FeatherIcon>
            </a>
          </span>
          <span className="fcIcX yb">
            <a href={youtube} target="_blank">
              <FeatherIcon height="20" width="20" icon="youtube"></FeatherIcon>
            </a>
          </span>
        </div>
      </div>
      <div className="Fright Fdiv">
        <form className="frmSubs">
          <h1>Shyiramo Emayili ukurikirane andi makuru</h1>
          <label>Emayili</label>
          <input
            autoComplete="off"
            value={upValue.email}
            onChange={handleChange}
            name="email"
            type="text"
            className="form-control mb-1"
            placeholder="Email"
          />

          {error && <span class="text-danger">{errorMessage}</span>}
          {success && (
            <span class="text-green">Murakoze, igikorwa cyagenze neza.</span>
          )}

          <input
            onClick={handleSubmit}
            className="SubBtn"
            type="submit"
            value="Ohereza"
          />
        </form>
        <div className="BtMx">Copyright @ Kinyamateka {year}</div>
      </div>
    </div>
  );
}
