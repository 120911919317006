import { useEffect,useState} from "react"
import NoContent from "./pageComp/NoContent";

export default function Books(p){
    const [bookList,setBookList]=useState([]);
    const limit= (typeof p.limit ==='undefined' || p.limit===null) ? 50 : p.limit;
    const _linktyp= (typeof p.type ==='undefined' || p.type===null) ? 'bk' :p.type;
    let linkT= (_linktyp=='bk') ? 'books-list' : 'magasins-list';
    useEffect(() => {
      let cou=1;
      const showDatas=(data)=>{
      let arrays=data.map((data,i) => {
        if(i<limit){
          let desc=data.description,test=(typeof desc==='undefined') ? true :false,
          cldD=(typeof desc==='undefined') ? ' _clsYt':'';
          desc=(typeof desc==='undefined') ? '':(desc.length>100) ? desc.slice(0,80)+"..." : desc;
         
          return <div className="bookList">
            <div className="bookLeft"><img className="_jhyDF" data-src={data.image}/></div>
            <div className="bookRight">
        <div className={"bookTitle _booL"+cldD}>{data.title}</div>
        {_linktyp=='bk' && <span>
        <div className={"bookAuthor _booL"+cldD}>{test==false && 'Author : '+data.author}</div>
        <div className={"Price2_1 w-full _booL"}>
        <p className={"price1"+cldD}>{test==false && 'RWF'+data.price}</p>
        <p className={"price2"+cldD}>{test==false && 'RWF'+data.price2}</p>
        </div>
        </span>
        }
        <div className={"descrip min-h-[80px] _booL"+cldD}>{desc}</div>
        {_linktyp=='bk' &&
        <div className={"telephone _booL"+cldD}>{test==false && data.address+"/"+data.phone}</div> 
      }
            </div>
          </div>
      }
        });
        setBookList(arrays);
      }
        showDatas([{},{},{},{},{},{},{},{}])
        fetch(p._lk+linkT+"/",{
            method:'GET',headers:{'Content-Type':'application/json'}
            }).then(res=>res.json()).then(ty=>{showDatas(ty)});
    },[])
    useEffect(()=>{
    var elem=document.querySelectorAll('._jhyDF');
   Array.from(elem).forEach(async(ny)=>{
     ny.classList.add('hidden');ny.src=ny.dataset.src;
     ny.onload=()=>{ny.classList.remove('hidden');}
    })
    })
    return (
   <div className={"div_bNyx min-h-[300px] bdv_books "+p.claS}>
    <div className="_dvXcX">{bookList}</div>
   {(p.claS!='XcnyNs') &&
    <div className="_pagination">
        <button className="_pPrev _pgN">Ahabanza</button>
        <ul className="liPagination">
        <li>1</li>
        </ul>
        <button  className="_pnext _pgN">Ahakurikira</button>
    </div>
    }
   </div>
    );

}