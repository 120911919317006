import { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SubscriberLogin from "./SubscriberLogin";
import HomeNavTop from "./HomeNavTop";
import SubscriberSignUp from "./SubscriberSignUp";
import ForgetPassword from "./ForgetPassword";
import Login from "./Login";
import HomeLogoAdvert from "./HomeLogoAdvert";
import Menus from "./Menus";
import News from "../../pages/News";
import Magazine from "../../pages/Magazine";
import Books from "../../pages/Books";
import Letters from "../../pages/Letters";
import Archives from "../../pages/Archives";
import About from "../../pages/About";
import MovingText from "./MovingText";
import Footer from "./Footer";
import NotFound from "./NotFound";
import Amatangazo from "./Amatangazo";
import ShowKinyPdf from "../../pages/pageComp/ShowKinyPdf";
import AlertSubs from "./AlertSubs";
import Subscriberon from "./Subscriberon";

export default function (p) {
  const _link = p._lk;

  let pAdvert = (
    <p className="p-adverts">
      Wifuza kwamamaza hano ?? Tumenyeshe kuri numero +250 781 157 393 cyangwa
      kuri email ikinyamateka@gmail.com
    </p>
  );

  const [showKiny, isShowKinyx] = useState(false),
    [KinyPdf, setKinyPdf] = useState({}),
    [hideC, isHideC] = useState("");
  const isShowKiny = () => {
    isShowKinyx(true);
    isHideC("_HiddeNx");
  };
  const hideKinya = () => {
    isShowKinyx(false);
    isHideC("");
  };
  const [memberLogin, isShowMemberLogin] = useState(false);
  const [subcrP, isShowSubscr] = useState(false);
  const [newMemberSignUp, isShowNewMemberSignUp] = useState(false);
  const [forgetPassword, isShowForgetPassword] = useState(false);
  const [adminLogin, isShowAdminLogin] = useState(false);
  const [loginTop, changeLoginTop] = useState(false);
  const [newsPP, setNewsP] = useState(1);
  let arx_key = [],
    keys = ["Shift", "Control", "K"];
  const [isShowSub, showSub] = useState(false);

  const hideSub = () => {
    showSub(false);
    isHideC("");
  };
  const showSubs = () => {
    showSub(true);
    isHideC("_HiddeNx");
  };

  const showAdminLogin = () => {
    isShowAdminLogin(true);
    isHideC("_HiddeNx");
    isShowForgetPassword(false);
    isShowMemberLogin(false);
    isShowNewMemberSignUp(false);
  };
  //

  const hideAdminLogin = () => isShowAdminLogin(false);
  const showForget = () => isShowForgetPassword(false);
  const hideForget = () => isShowForgetPassword(false);
  const showMemberLogin = () => {
    isShowMemberLogin(true);
    isHideC("_HiddeNx");
  };
  const hideMemberLogin = () => {
    isShowMemberLogin(false);
    isHideC("");
  };
  const showMemberSignUp = () => {
    isShowNewMemberSignUp(true);
    isHideC("_HiddeNx");
  };
  const hideMemberSignUp = () => {
    isShowNewMemberSignUp(false);
    isHideC("");
  };
  const hideChooseSubs = () => {
    isShowSubscr(false);
    isHideC("");
  };

  const showChoosesubs = () => {
    isShowMemberLogin(false);
    isHideC("_HiddeNx");
    isShowForgetPassword(false);
    isShowNewMemberSignUp(false);
    isShowSubscr(true);
  };

  const showForgetHideLogin = () => {
    isShowMemberLogin(false);
    isHideC("_HiddeNx");
    isShowSubscr(false);
    isShowForgetPassword(true);
  };
  const showLoginHideForget = () => {
    isShowForgetPassword(false);
    isShowMemberLogin(true);
    isHideC("_HiddeNx");
  };

  const showSignHideLog = () => {
    isShowNewMemberSignUp(true);
    isShowMemberLogin(false);
    isHideC("_HiddeNx");
  };
  const showLogHideSign = () => {
    isShowNewMemberSignUp(false);
    isShowMemberLogin(true);
    isHideC("_HiddeNx");
  };

  const handlekeyPress = useCallback((event) => {
    arx_key.push(event.key);
    if (
      arx_key.length == 3 &&
      arx_key[0] == keys[0] &&
      arx_key[1] == [keys[1]] &&
      arx_key[2] == keys[2]
    ) {
      showAdminLogin();
      arx_key = [];
    }
    if (event.key == keys[0]) {
      arx_key = [];
      arx_key.push(keys[0]);
    } else if (arx_key.length >= 3 || arx_key[0] != keys[0]) {
      arx_key = [];
    }
  }, []);
  useEffect(() => {
    document.addEventListener("keydown", handlekeyPress);
    return () => {
      document.removeEventListener("keydown", handlekeyPress);
    };
  }, [handlekeyPress]);

  /*----------------- hanfle  menu -----------*/
  const [AudioWindow, isAudioShow] = useState(false);
  const closeAudio = () => isAudioShow(false);
  const showAudio = () => isAudioShow(true);

  //cipher
  const cipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);

    return (text) =>
      text
        .split("")
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join("");
  };
  const decipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);
    return (encoded) =>
      encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join("");
  };
  const _cipher = cipher("$kiny@"),
    _decipher = decipher("$kiny@");

  // fetch menus
  const [links_, setLinks] = useState([]);
  const sortMenu = (menu) => {
    const menuArr = [];
    let i = 0;
    menu.forEach((mn) => {
      if (mn.id > 5) {
        mn._type = "menu";
        if (mn.id == 6) {
          menuArr[i] = (
            <Route
              key={mn.id}
              path={"/" + mn.link + "/*"}
              element={<Amatangazo type="tg" ty={mn} _lk={_link} />}
            ></Route>
          );
          i++;
        }
        if (mn.id == 9) {
          menuArr[i] = (
            <Route path={"/" + mn.link} element={<About _lk={_link} />}></Route>
          );
          i++;
        }
        if (mn.id == 7) {
          mn.submenu_menu.forEach((subM) => {
            var newM = JSON.parse(JSON.stringify(mn));
            newM._type = "subM";
            if (subM.id == 6) {
              menuArr[i] = (
                <Route
                  key={subM.id + mn.id}
                  path={"/" + subM.link}
                  element={
                    <Archives
                      setNewsP={setNewsP}
                      subDv={showSubs}
                      showKiny={isShowKiny}
                      kinyPdf={setKinyPdf}
                      _lk={_link}
                      ty={newM}
                      subM={subM}
                    />
                  }
                ></Route>
              );
              i++;
            }
            if (subM.id == 7) {
              menuArr[i] = (
                <Route
                  key={subM.id + mn.id}
                  path={"/" + subM.link}
                  element={<Magazine _lk={_link} ty={newM} subM={subM} />}
                ></Route>
              );
              i++;
            }
            if (subM.id == 8) {
              menuArr[i] = (
                <Route
                  key={subM.id + mn.id}
                  path={"/" + subM.link}
                  element={<Books _lk={_link} ty={newM} subM={subM} />}
                ></Route>
              );
              i++;
            }
            if (subM.id == 9) {
              menuArr[i] = (
                <Route
                  key={subM.id + mn.id}
                  path={"/" + subM.link + "/*"}
                  element={
                    <Amatangazo
                      type="am"
                      tx="sub"
                      _lk={_link}
                      ty={newM}
                      subM={subM}
                    />
                  }
                ></Route>
              );
              i++;
            }
          });
        }
      }
    });
    setLinks(menuArr);
  };

  const cipher2 = (cipher) => {
    cipher = +parseInt(cipher, 16).toString().split("").reverse().join("");
    return (cipher + 9).toString(36);
  };

  const [menusX, isMenus] = useState([]);
  useEffect(() => {
    //_link
    fetch("https://kinyamateka.rw/api/menulist/", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((ty) => {
        let newMenus = [];
        ty.forEach((mnU, i) => {
          var pds = _cipher("a#" + mnU.id + "#a"),
            pds2 = +parseInt(pds, 16).toString().split("").reverse().join(""),
            pds3 = (pds2 + 9).toString(36);
          mnU._lkI1 = pds;
          mnU._lkI2 = pds2;
          mnU._lkID = pds3;
          mnU.test = "";
          mnU._lkID = window.btoa(mnU.title);
          mnU.link = mnU.title
            .split(" ")
            .join("-")
            .toLowerCase()
            .split("'")
            .join("");
          var subM = [];
          mnU.submenu_menu.forEach((subs) => {
            subs._lkID = _cipher(subs.id + "");
            subs.link =
              mnU.link +
              "_" +
              subs.title.split(" ").join("-").toLowerCase().split("'").join("");
          });
          newMenus.submenu_menu = subM;
          newMenus[i] = mnU;
        });

        sortMenu(newMenus);
        isMenus(newMenus);
      });
  }, []);

  /*fetch ads*/
  const [ads, setads] = useState([]);
  useEffect(() => {
    fetch(_link + "adslist/", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((ty) => {
        setads(ty);
      });
  }, []);

  return (
    <div className="Public-pg">
      <HomeNavTop
        subDv={showSubs}
        showSubs={showChoosesubs}
        Account={loginTop}
        showAccount={changeLoginTop}
        lgn={showMemberLogin}
        signUp={showMemberSignUp}
      />
      <HomeLogoAdvert ptext={pAdvert} ads={ads} />
      <Menus
        setNewsP={setNewsP}
        subDv={showSubs}
        menus={menusX}
        _lk={_link}
        c={AudioWindow}
        audioS={showAudio}
        audioH={closeAudio}
      />
      {isShowSub && <AlertSubs newsP={newsPP} hideSub={hideSub} />}
      <div className={"DvContainer " + hideC}>
        <Routes>
          <Route path="*" element={<NotFound />}></Route>
          <Route
            path="/*"
            index
            element={
              <News
                setNewsP={setNewsP}
                subDv={showSubs}
                showKiny={isShowKiny}
                kinyPdf={setKinyPdf}
                ads={ads}
                ptext={pAdvert}
                allM={menusX}
                _lk={_link}
              />
            }
          ></Route>
          {links_}
        </Routes>
      </div>
      {showKiny && (
        <ShowKinyPdf
          hideKinya={hideKinya}
          showKiny={isShowKiny}
          setKinyPdf={setKinyPdf}
          kinyPdf={KinyPdf}
        />
      )}
      <Footer menus={menusX} />

      {memberLogin && (
        <SubscriberLogin
          showSubs={showChoosesubs}
          showAccount={changeLoginTop}
          fget={showForgetHideLogin}
          signUpX={showSignHideLog}
          lgnS={showLogHideSign}
          signUp={showMemberSignUp}
          lgn={hideMemberLogin}
        />
      )}
      {newMemberSignUp && (
        <SubscriberSignUp
          signUpX={showSignHideLog}
          lgnS={showLogHideSign}
          lgn={hideMemberSignUp}
        />
      )}
      {forgetPassword && <ForgetPassword lgn={showLoginHideForget} />}
      {subcrP && (
        <Subscriberon showSubs={showChoosesubs} lgn={hideChooseSubs} />
      )}
      {adminLogin && <Login lgn={hideAdminLogin} />}
    </div>
  );
}
