import FeatherIcon from 'feather-icons-react';
export default function DashB(){
    return (
    <div className="AdDash flex flex-row">
        <div className=' self-start p-2 w-[70%] h-auto '>
            <div className='w-full  min-h-[150px] mb-5 flex flex-row'>
            <div className="DshNmT">
<div className="DashSCrd text-[14px] p-3 ">
                <div className="DvSct">
                <p className="Ptitl p-2 relative flex flex-row w-full">Kinyamateka
                <p className='self-end '><FeatherIcon icon='database'/></p>
                </p>
                </div>
                <div className="flex flex-row w-full" >
                 <div className="w-[60%] flex justify-center pl-5 flex-col">
                     <span className="text-[30px]">542</span>
                     <p>Total</p>
                 </div>
                 <div className="w-[40%] flex justify-center flex-col">
                    <span>
                    <p>Locked</p>
                     <span>416</span>
                    </span>
                    <span>
                    <p>Hidden</p>
                     <span>100</span>
                    </span>
                    
                 </div>

                </div>
                
                <div>

                </div>
                </div>
                 </div>
            
                 <div className="DshNmT">
    <p className="Ptitl p-2 relative flex flex-row w-full">Books</p>
                
                 </div>
                 <div className="DshNmT">
    <p className="Ptitl p-2 relative flex flex-row w-full">Magazines</p>
                 </div>
            </div>
            <div className='w-full bg-white min-h-[300px]'>
                
            </div>
        
            <div className='w-full  min-h-[150px] mb-5 flex flex-row mt-4'>
            <div className="DshNmT">
    <p className="Ptitl p-2 relative flex flex-row w-full"></p>
            </div>
        
            <div className="DshNmT">
    <p className="Ptitl p-2 relative flex flex-row w-full"></p>
            </div>
            <div className="DshNmT">
    <p className="Ptitl p-2 relative flex flex-row w-full"></p>
            </div>
            
            </div>

        </div>

        <div className='bg-transparent self-start p-2 w-[30%] h-auto flex flex-col'>
            <div className='dvTx w-full bg-white min-h-[300px]'>
            <p className="Ptitl p-2 relative flex flex-row w-full">Subscribers</p>
            </div>

            <div className='dvTx w-full bg-white min-h-[300px] mt-4'>
            <p className="Ptitl p-2 relative flex flex-row w-full">News</p>
            </div>


        </div>
       

    </div>




    );
}

/*
<div className="DshNmT">
<div className="DashSCrd text-[14px] p-3 hidden">
                <div className="DvSct">
                <p className="Ptitl p-2 relative flex flex-row w-full">Kinyamateka
                <p className='self-end '><FeatherIcon icon='database'/></p>
                </p>
                </div>
                <div className="flex flex-row w-full" >
                 <div className="w-[60%] flex justify-center pl-5 flex-col">
                     <span className="text-[30px]">542</span>
                     <p>Total</p>
                 </div>
                 <div className="w-[40%] flex justify-center flex-col">
                    <span>
                    <p>Locked</p>
                     <span>416</span>
                    </span>
                    <span>
                    <p>Hidden</p>
                     <span>100</span>
                    </span>
                    
                 </div>

                </div>
                
                <div>

                </div>
                </div>
                 </div>
*/