import FeatherIcon from 'feather-icons-react';
import { useState } from 'react';
import SubAct from './SubAct';
import SubSec from './SubSec';
const SubPlan=()=>{
    const [PyN,isPy]=useState(false);
    const [st1,isSt1]=useState(true);
    const showPy=()=>{isSt1(false); isPy(true);}
    const showSt1=()=>{isPy(false);isSt1(true);}

     

    return (
        <div className='DvXD'>
        {st1 && <SubAct hp={showPy}/>}
        {PyN && <SubSec hp={showSt1}/>}
        </div>
    )

}
export default SubPlan;