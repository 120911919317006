import FeatherIcon from "feather-icons-react";
import { useState } from "react";
const SubAct = (p) => {
  const lixS = (n) => {
    const l1D = [
        "Amakuru Agezweho",
        "Kinyamateka zigezweho",
        "Kinyamateka Print",
      ],
      lix = l1D.map((l1, i) => {
        var check = i == 2 && n == 1 ? "x" : "check";
        return (
          <li key={i}>
            <span className="Ls1">
              <FeatherIcon height="20" width="20" icon={check} />
            </span>
            <span className="Ls2">{l1}</span>
          </li>
        );
      });

    return lix;
  };

  return (
    <div className="SbPlN">
      <h1 className="Phdeade">Choose your subscription plan</h1>
      <div className="sUBcARD">
        <div className="SubCard Sub1">
          <p className="subPlx">Starndard</p>
          <p className="XPrice">
            RWF 5,000
            <sub className="SubXPr">/Umwaka</sub>
          </p>
          <span className="sPbbUY">
            <button onClick={p.hp}>Gura Ubu</button>
          </span>
          <ul className="DscXwQ">{lixS(1)}</ul>
        </div>

        <div className="SubCard">
          <p className="subPlx">Premium</p>
          <p className="XPrice">
            RWF 10,000
            <sub className="SubXPr">/Umwaka</sub>
          </p>
          <span className="sPbbUY SubnX2">
            <button onClick={p.hp}>Gura Ubu</button>
          </span>
          <ul className="DscXwQ">{lixS(2)}</ul>
        </div>
      </div>
    </div>
  );
};
export default SubAct;
