import FeatherIcon from 'feather-icons-react';
export default function Dashboard(){
    return (
        <div className="Dashboard">
        <div className="DivLeft">
            <div className="DivDshCrd">
                <div className='cardON crd1'>
                    <p className='sUbLft'>
                        <FeatherIcon icon='bar-chart'/>
                        Current Subscription</p>
                    <p className='DyLft'>360 <sub className='SyBS'>Days/Left</sub></p>
                     <p className='Dcgd'>02/01/2022 - 02/01/2023</p>
                     </div>
                    <div className='cardON crd2'>
                    </div>
            </div>
            <div className="DvHistory">
                <div className="subTl">Subscription History</div>
                <div className="SubSTbl">
                    <table className="tblDS">
                        <thead>
                            <tr>
                                <th>N<sup>O</sup></th>
                                <th>From - To</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                     <td>1</td>
                    <td>1/06/2024 - 1/05/2025</td>
                    <td>
            <div className='DivXu'>
            <p className="SttBtN">Payment Pending...</p>
            <button>Confirm Payment</button>
            </div>
                        </td>
                    </tr> 
                    <tr>
                     <td>2</td>
                    <td>1/05/2023 - 1/05/2024</td>
                    <td><p className="SttBtN wtht">Waiting confirmation...</p></td>
                    </tr>
                    <tr>
                    <td>3</td>
                    <td>1/05/2022 - 1/05/2023</td>
                    <td><p className="SttBtN crnt">Active</p></td>
                    </tr> 

                    <tr>
                    <td>4</td>
                    <td>1/0/2021 - 1/05/2022</td>
                    <td><p className="SttBtN expird">Expired</p></td>
                    </tr>  

                        </tbody>
                    </table>
                    </div>

                </div>

        </div>
        <div className="DivRight">
            <p className="DivLcd">Subscriber Information</p>
            <div className="EdirSec">
            <label>Amazina yombi</label>
            <div className='DcFlex'>
        <input type='text' disabled maxLength={40} className="inputX" value='Tuyisabe Pacifique' />
        <span className="jjdfZ"><FeatherIcon height='16' width='16' icon='edit-2'/></span>
            </div>
            </div>

            <div className="EdirSec">
            <label>Numero ya telephone</label>
            <div className='DcFlex'>
            <input type='text' disabled maxLength={40} className="inputX" value='0785676288' />
            <span className="jjdfZ"><FeatherIcon height='16' width='16' icon='edit-2'/></span>
            </div>
            </div>
        
            <div className="EdirSec">
            <label>Emeyili</label>
            <div className='DcFlex'>
            <input type='text' disabled maxLength={40} className="inputX" value='tuyi89@gmail.com' />
            <span className="jjdfZ"><FeatherIcon height='16' width='16' icon='edit-2'/></span>
            </div>
            </div>

            <div className="EdirSec">
            <label>Diyosezi / Paruwasi</label>
            <div className='DcFlex'>
            <input type='text' disabled maxLength={40} className="inputX" value='Kabgayi/Kabgayi' />
            <span className="jjdfZ"><FeatherIcon height='16' width='16' icon='edit-2'/></span>
            </div>
            </div>
            
            
           
           
    
    

            </div>
        
            </div>
    );
}