import Books from "./Books";
import NoContent from "./pageComp/NoContent";

export default function Magazine(p){
    return (
   <div className="div_bNyx min-h-[300px] _magazines">
     <div className="_dvXcX">
      <Books _lk={p._lk} type='mg'/> 
     </div>
   </div>
    );

}