import Pagination from "./Pagination";

export default function SubScriber(){
    return (
        <div className="KinYX w-[90%] relative left-[5%]">
         <p className="w-full p-5 text-[20px] font-semibold">Subscriber List</p>
         <table className="w-full">
                    <thead class="bg-gray-50">
                        <tr>
                            <th class="px-6 py-2 text-xs text-gray-500">
                                ID
                            </th>
                            <th class="px-6 py-2 text-xs text-gray-500">
                                Name
                            </th>
                            <th class="px-6 py-2 text-xs text-gray-500">
                                Email
                            </th>
                            <th class="px-6 py-2 text-xs text-gray-500">
                                Starting-date
                            </th>
                            <th class="px-6 py-2 text-xs text-gray-500">
                                Ending Date
                            </th>
                            <th class="px-6 py-2 text-xs text-gray-500">
                                Edit
                            </th>
                            <th class="px-6 py-2 text-xs text-gray-500">
                                Delete
                            </th>
                            <th class="px-6 py-2 text-xs text-gray-500">
                                Details
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white">
                        <tr class="whitespace-nowrap">
                            <td class="px-6 py-4 text-sm text-gray-500">
                                1
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    Tuyisabe Pacifique
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-500">mssdsd@gmail.com</div>
                            </td>
                            <td class="px-6 py-4 text-sm text-gray-500">
                                2021-1-12
                            </td>
                            <td class="px-6 py-4 text-sm text-gray-500">
                                2021-1-12
                            </td>
                            <td class="px-6 py-4">
                                <a href="#" class="px-4 py-1 text-sm text-white bg-blue-400 rounded">Edit</a>
                            </td>
                            <td class="px-6 py-4">
                                <a href="#" class="px-4 py-1 text-sm text-white bg-red-400 rounded">Delete</a>
                            </td>
                            <td class="px-6 py-4">
                                <a href="#" class="px-4 py-1 text-sm text-white bg-red-400 rounded">More</a>
                            </td>
                        </tr>
                        <tr class="whitespace-nowrap">
                            <td class="px-6 py-4 text-sm text-gray-500">
                                2
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    Mugwaneza Abdullah
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-500">abdullah@gmail.com</div>
                            </td>
                            <td class="px-6 py-4 text-sm text-gray-500">
                                2021-1-12
                            </td>
                            <td class="px-6 py-4 text-sm text-gray-500">
                                2021-1-12
                            </td>
                            <td class="px-6 py-4">
                                <a href="#" class="px-4 py-1 text-sm text-white bg-blue-400 rounded">Edit</a>
                            </td>
                            <td class="px-6 py-4">
                                <a href="#" class="px-4 py-1 text-sm text-white bg-red-400 rounded">Delete</a>
                            </td>
                            <td class="px-6 py-4">
                                <a href="#" class="px-4 py-1 text-sm text-white bg-red-400 rounded">More</a>
                            </td>
                        </tr>
                        <tr class="whitespace-nowrap">
                            <td class="px-6 py-4 text-sm text-gray-500">
                                3
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                   Pacifique Mugwaneza
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-500">iquemugwan@gmail.com</div>
                            </td>
                            <td class="px-6 py-4 text-sm text-gray-500">
                                2021-1-12
                            </td>
                            <td class="px-6 py-4 text-sm text-gray-500">
                                2021-1-12
                            </td>
                            <td class="px-6 py-4">
                                <a href="#" class="px-4 py-1 text-sm text-white bg-blue-400 rounded">Edit</a>
                            </td>
                            <td class="px-6 py-4">
                                <a href="#" class="px-4 py-1 text-sm text-white bg-red-400 rounded">Delete</a>
                            </td>
                            <td class="px-6 py-4">
                                <a href="#" class="px-4 py-1 text-sm text-white bg-red-400 rounded">More</a>
                            </td>
                        </tr>

                    </tbody>
                </table>
        <Pagination n={1}/>
        </div>
    )
}