import FeatherIcon from 'feather-icons-react';
import { NewsAudio, NewsPhoto, NewsVideo } from './NewsOther';
import { useEffect, useState } from 'react';
export default function NewsP(p){
    const [isVx,shwV]=useState(false),[isAu,shwA]=useState(false),
    [isP,shwP]=useState(false);
    const Hv=()=>shwV(false) , Ha=()=> shwA(false), Hp=()=>shwP(false);
    const showV=()=>{shwA(false);shwP(false);shwV(true);}
    const showA=()=>{shwV(false);shwP(false);shwA(true);}
    const showP=()=>{shwV(false);shwA(false);shwP(true);}


    useEffect(()=>{
let ip=Array.from(document.querySelectorAll('.ipNBY'));
ip.forEach((ipx)=>{
    var prtx=ipx.parentElement.nextElementSibling, chl=ipx.children[0];
    console.log(chl);
ipx.addEventListener('click',()=>{
    prtx.classList.toggle('HdX');
});
   });

    },[]);
    return(
        <div>
<div className='BNCH'>
 <span className='SPnc' onClick={showP}><FeatherIcon height='20' width='20' icon='camera' /></span>
 <span className='SPnc' onClick={showA}><FeatherIcon height='20' width='20' icon='mic' /></span>
 <span className='SPnc' onClick={showV}><FeatherIcon height='20' width='20' icon='video'/></span> 
</div>
<div className='PlayerX'>
    </div>       
        <label className="lblL"> Paragraph {p.np}
<span className='ipNBY'><FeatherIcon icon='chevron-up' /></span>
        </label>
        <div className='DivXs'>
        <textarea className='TextAr ARN'></textarea>
       </div>
    {isVx && <NewsVideo np={p.np} h={Hv}/>}
    {isP && <NewsPhoto np={p.np} h={Hp}/>}
    {isAu && <NewsAudio np={p.np} h={Ha}/>}
      </div>
    )

}