import { useCallback, useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import LoginContainer from "./LoginContainer";
import { useNavigate } from "react-router-dom";
import http from "../../config/axiousBase";

export default function SubscriberLogin(p) {
  let nav = useNavigate();

  function lgN(e) {
    e.preventDefault();
    nav("/s");
  }
  
  let uservs = { email: "", password: "" };

  const [login, isLogin] = useState(false),
    [error, setError] = useState(false),
    [success, setSuccess] = useState(false),
    [errMessage, setErrMessage] = useState(''),
    [loading, setLoading] = useState(false),
    [userV, setValues] = useState(uservs),
    /**
     * -------------------------------------------------------------------
     * Check user subscription
     * ----------------------------------------------------------------------------
     */
    checkSubscription = async (data) => {
      const endpoint = "check-subscription/" + data.user_id + '/';
      try {
        const response = await http.get(endpoint);
        if (response) {
          var CV = { user: data, sub: response.data },
            locName = "_kiny_";
          localStorage.setItem(locName, JSON.stringify(CV));

          p.showAccount("lgn" + data.user_id);
          
          if (response.data.length > 0) {
            setTimeout(() => {
              setSuccess(false);
              p.lgn();
            }, 1500);
          } else {
            setTimeout(() => {
              setSuccess(false);
              p.lgn();
              //p.showSubs();
            }, 1500);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * -------------------------------------------------------------------
     * Handle user inputs change
     * ----------------------------------------------------------------------------
     */
    handleChange = (e) => {
      const name = e.target.name,
        value = e.target.value;
      setValues((values) => ({ ...values, [name]: value }));
      setError(false);
    },
    /**
     * -------------------------------------------------------------------
     * User login view
     * ----------------------------------------------------------------------------
     */
    handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      setError(false);
      try {
        const endpoint = "login/";
        const response = await http.post(endpoint, userV);

        if (response) {
          setLoading(false);
          setSuccess(true);
          checkSubscription(response.data);
        }
      } catch (error) {
        setLoading(false);
        if(error?.response?.status == 401){
          setErrMessage('Reba muri inbox ya email yawe ubundi uverifiye imeri wongere mukanya. ')
          setError(true)
          
        }else if(error?.response?.status == 403){
          setErrMessage('Izina ukoresha cyangwa ijambo ryibanga ntabwo byemewe. ')
          setError(true)
        }else{}
      }
    };

  return (
    <LoginContainer p={p}>
      <form className="FrmLGN" onSubmit={lgN}>
        <div className="InCont">
          <h1 className="lgn-titl">Aho umunyamuryango y'injirira</h1>
        </div>
        <div className="InCont">
          <label>Numero ya terefone /Aderesi ya imeri</label>
          <input
            autoComplete="off"
            id="email"
            name="email"
            value={userV.email}
            maxLength={40}
            onChange={handleChange}
            type="email"
            className="form-control p-2 mb-2 mt-1"
            placeholder="Email address"
          />
        </div>
        <div className="InCont mb-1">
          <label>Ijambobanga</label>
          <input
            autoComplete="off"
            id="password"
            value={userV.password}
            name="password"
            onChange={handleChange}
            type="password"
            maxLength={16}
            className="form-control p-2 mt-1"
            placeholder="Password"
          />
        </div>

        <div className="InCont">
          {success && (
            <div class="alert alert-success" role="alert">
              Kwinjira byagenze neza, Murakoze
            </div>
          )}

          {error && (
            <div class="alert alert-danger" role="alert">
              {errMessage}
            </div>
          )}
        </div>

        <div className="InCont">
          {!loading && !success && (
            <button onClick={handleSubmit} className="btn btn-primary">
              Injira
            </button>
          )}

          <div
            className="flex items-center justify-center w-full"
            role="status"
          >
            {loading && (
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </div>

          <div className="BvyNB flex items-center flex-col justify-center w-full relative">
            <button
              onClick={p.signUpX}
              className="BtnNS text-[#E703F9] pb-4 font-light"
              type="button"
            >
              Kora konti yo kwiyandikisha
            </button>
            <a
              onClick={p.fget}
              className="inline-block align-baseline  text-sm text-blue-500 hover:text-blue-800"
              href="#"
            >
              Wibagiwe ijambo ryibanga?
            </a>
          </div>
        </div>
      </form>
    </LoginContainer>
  );
}
