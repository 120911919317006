import FeatherIcon from 'feather-icons-react';
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
export default function NewRecent(p){
    const _lk=p._lk,typ=p.typ,ty2=p.tt,subM=(typeof p.subM==='undefined') ? {}:p.subM,
    news=p.news;
    const share=p.share;
    let arry='id' in subM ? subM.id : 0;arry=+typ.id + +arry;
   let pTitle=(Object.keys(subM).length==0) ? typ.title : typ.title+"  "+subM.title; 
    const [list_,setList]=useState([]);
   let linkX_v='';
    if(typ._type=='menu'){linkX_v='newspaginationmenu/'+typ.id+'/';}
    if(typ._type=='subM'){linkX_v='newslistsub/'+subM.id+'/';}

    useEffect(()=>{
        var lazyloadImages = document.querySelectorAll(".lazyX");  
            var lazyloadThrottleTimeout;
            
            function lazyload () {
              if(lazyloadThrottleTimeout) {
                clearTimeout(lazyloadThrottleTimeout);
              }    
              
              lazyloadThrottleTimeout = setTimeout(function() {
                  var scrollTop = window.pageYOffset;
                  lazyloadImages.forEach(function(img) {
                      if(img.offsetTop < (window.innerHeight + scrollTop)) {
                        img.src = img.dataset.src;
                        img.onerror=()=>{img.parentElement.removeChild(img);}
                        img.classList.remove('lazyX');
                      }
                  });
                  if(lazyloadImages.length == 0) { 
                    document.removeEventListener("scroll", lazyload);
                    window.removeEventListener("resize", lazyload);
                    window.removeEventListener("orientationChange", lazyload);
                  }
              }, 20);
            }
            
            document.addEventListener("scroll", lazyload);
            window.addEventListener("resize", lazyload);
            window.addEventListener("orientationChange", lazyload);

    })

    const convertDate=(date)=>{
        var datex=new Date(date).getTime(),todayDate=new Date().getTime(),
        tDf=todayDate-datex,tM='Second';
        let dateM=tDf/1000; 
        if(dateM>=60 && tM=='Second'){dateM=dateM/(60);tM='Minute';}
        if(dateM>=60 && tM=='Minute'){dateM=dateM/(60);tM='Hour'}
        if(dateM>=24 && tM=='Hour'){dateM=dateM/24;tM='Day'}
        if(dateM>=30 && tM=='Day'){dateM=dateM/(365.5/12);tM='Month'}
        if(dateM>=12 && tM=='Month'){dateM=dateM/12;tM='Year';}
        dateM= Math.round(dateM);
         return (dateM>1) ? dateM+' '+tM+'s'+' Ago' : dateM+' '+tM+' Ago'; 
      } 
    const [linkX_,setLink_]=useState(_lk+linkX_v);
    useEffect(()=>{
        elements({test:true,results:[{},{},{},{},{},{},{},{},{},{}]});
        fetch(linkX_,{
            method:'GET',headers:{'Content-Type': 'application/json'}
        }).then(res=>res.json()).then((ty)=>{elements(ty);})
    },[arry,linkX_])
    const sendLink=(link)=>{
        if(link!==null){setLink_(link)}
    }
    const elements=(list_X)=>{
        var sliders=[],limit=(ty2=='HM') ? 10 : 50,ii=0;
        var clr=(list_X.test==true) ? ' _testx':'';
        var nextLink=list_X.next,prevLink=list_X.previous,
        next_C=nextLink===null ? ' CdrTR' :'',prev_C=prevLink===null ? ' CdrTR' : '';          
        let prevNext=(list_X.length <= limit) ? [] :
            <div className='w-full mt-3 p-4 flex justify-center '>
    <button onClick={e=>{sendLink(prevLink)}} className={'btnCL'+prev_C}>Prev</button>
    <button onClick={e=>{sendLink(nextLink)}} className={'btnCL'+next_C}>Next</button>
            </div>;
    list_X.results.forEach((news,i)=>{
        if((ty2=='SG' && i>3) || ty2=='HM' || prevLink!=null){
        if(ii<limit){
        let Ntitle='',Xc=0;
            if(list_X.test!==true){
    Ntitle=news.title.split('');
    if(Ntitle.length>80){Ntitle=Ntitle.slice(0,80);Xc=1;}
     Ntitle=Ntitle.join('');if(Xc==1){Ntitle=Ntitle+"...";}
            }
    
     let _Link='/'+typ.link+'/'+window.btoa("News#"+news.id+"#tGS");
     let fbShare=share.facebook+share.base_Link+'#'+_Link,
     twitterShare=share.twitter+encodeURIComponent(share.base_Link+'#'+_Link)
      +'&&text='+encodeURIComponent(news.title),
     whattapp=share.whattapp+encodeURIComponent(news.title+" "+share.base_Link+'#'+_Link);
     
//

   sliders[i]=
       <div className={"SliderX _receNtSBV"+clr}>
   <div className="NewStop">
   <Link to={_Link} state={news} href='#' key={news.id}>
{(list_X.test!==true) && <img data-src={news.main_photo} className='NewsIMG lazyX'/>}
   </Link>
   </div>
   <div className="NewsBottom px-1 pt-1 ">
       <div className="NewTitle text-left text-[13px] font-medium">
       <p className='xnhd'><Link className='' to={_Link} state={news} href='#' key={news.id}><p className='_PnewS'>{Ntitle}</p></Link></p>
       
       <div className='NbytC'>
       <span className='HdXV'>{(list_X.test!==true) && convertDate(news.created_timestamp)}</span>
       <a href={twitterShare} target='_blank' className='HdXV'>{(list_X.test!==true) && <FeatherIcon icon='twitter'  height='14' width='14'/>}</a>
       <a href={fbShare} target='_blank' className='HdXV'>{(list_X.test!==true) && <FeatherIcon icon='facebook' height='14' width='14'/>}</a>
    </div>

       </div>
     </div>
 </div>
 
        }
        ii++;
    }

    });
    setList([sliders,prevNext]);
    }

    
    
    return (
        <div className="Bnbc_Cx">
     <div className='BnyH_34AS flex-col'>{pTitle}</div>
     <div className="SlierNF">{list_[0]}{list_[1]}</div>   
        </div>
    )
}










/*
    useEffect(()=>{
       
        var slider=document.querySelector('.SlierNF');
        var buttonRight=document.querySelector('.ScroolRight');
        var buttonLeft=document.querySelector('.ScroolLeft');
        var scrollPerClick=443;
        var scroll_amount=0
    function sliderScrollLeft(){
        slider.scrollTo({
            top:0,
            left:(scroll_amount -=scrollPerClick),
            behavior:'smooth'

        });
        if(scroll_amount<0){scroll_amount=0;}
    }
   
     function sliderScrollRight(){
         if(scroll_amount <= slider.scrollWidth - slider.clientWidth){
             slider.scrollTo({
                 top:0,
                 left: (scroll_amount +=scrollPerClick),
                 behavior:'smooth'

             });
         }
     }

     buttonLeft.onclick=()=>sliderScrollLeft();
     buttonRight.onclick=()=>sliderScrollRight();

    },[])
    
<div className="NewX1"></div>
            <div className="NewX2">
                <span>Friday , January , 2021</span>
                <p>Kuri uyu wa 28 Gashyantare 2022, Papa Faransisiko yatoreye Musenyeri 
                    Musengamana Papias , 
                    wa Diyosezi ya Kabgayi kuba Umwepiskopi wa diyosezi ya Byumba wa 
                    Diyosezi ya Kabgayi kuba Umwepiskopi wa diyosezi ya Byumba.</p>
                <div className="MbgtX_vty"></div>
            </div>

*/