export default function AddMag(){
    return (
      <div className="GnFrM">
      <form className="FrmGeneral">
        <h1 className="FRMtX">Add a new Magazine</h1>

        <label className="lblL">Magazine title</label>
        <input type='text' className='INpX' placeholder='' name='' id='' />
        

        <label className="lblL">Magazine category</label>
        <select className="seLin">
              <option>Hobe</option>
              <option>Itoto magazine</option>
              <option>Urubuga rw’abagore</option>
              <option>Urunana</option>
            </select>
        

        <label className="lblL">Released Date</label>
        <input type='date' className='INpX' placeholder='' name='' id='' />
        
        <label className="lblL">Cover Image</label>
        <input type='file' className='INpX' placeholder='' name='' id='' />

        <label className="lblL">Magazine PDF</label>
        <input type='file' className='INpX' placeholder='' name='' id='' />
         
        <label className="lblL">Magazine short description</label>
        <textarea className='TextAr'></textarea>

        
        
       
        
        <div className="SINP"><input type='submit' className="SubInp" value='Save'/></div> 
      </form>
      
    </div>
    )
}