export default function SubSec(p){
    var pay1=['Pay On Bank Account 000606783827324 in Bank of Kigali',
    'Upload your Bank Slip in System or',
    'Contact 078633743 and Follow Instructions'];

    var pay2=['Kanda *183*534*535*1 kuri telephone muri banki ya equity',
    'Hamagara Numero 07838384 nurangiza kwishyura'];
    return (
        <div className="PyMeN">
    <span className="PynTtl">Uburyo bwo kwishyura</span>
    <div className="Ptytx">
        <ul>
        <li>Uburyo bwa mbere</li>
            <li>1.{pay1[0]}</li>
            <li>2.<span>{pay1[1]}</span>
            <span>{pay1[2]}</span></li>
        </ul>
      
        <ul>
        <li>Uburyo bwa kabiri</li>
            <li>1.{pay2[0]}</li>
            <li>2.<span>{pay2[1]}</span></li>
        </ul>
        <button className="bUYDxd">Emeza ko Wishyuye ubu</button>
        <button className="bUYDxd" onClick={p.hp} >Ishyura Nyuma</button>

        </div>

        </div>
    );
}