import Pagination from "./Pagination";

export default function Kiny(){
    return (
        <div className="KinYX w-[90%] relative left-[5%]">
         <p className="w-full p-5 text-[20px] font-semibold">Kinyamateka List</p>
         <table className="w-full">
                    <thead class="bg-gray-50">
                        <tr>
                            <th class="px-6 py-2 text-xs text-gray-500">
                                ID
                            </th>
                            <th class="px-6 py-2 text-xs text-gray-500">
                              # Number
                            </th>
                            <th class="px-6 py-2 text-xs text-gray-500">
                             # Date (Month/Year)
                            </th>
                            <th class="px-6 py-2 text-xs text-gray-500">
                             # Bottom Title
                            </th>
                            <th class="px-6 py-2 text-xs text-gray-500">
                              #  Edit
                            </th>
                            <th class="px-6 py-2 text-xs text-gray-500">
                               # Delete
                            </th>
                            <th class="px-6 py-2 text-xs text-gray-500">
                              #  More
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white">
                        <tr class="whitespace-nowrap">
                            <td class="px-6 py-4 text-sm text-gray-500">
                                1
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                  NO 928294
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-500">02/2022</div>
                            </td>
                            <td class="px-6 py-4 text-sm text-gray-500">
                              kinyamateka yo mukwezi kwa 2
                            </td>
                            <td class="px-6 py-4">
                                <a href="#" class="px-4 py-1 text-sm text-white bg-blue-400 rounded">Edit</a>
                            </td>
                            <td class="px-6 py-4">
                                <a href="#" class="px-4 py-1 text-sm text-white bg-red-400 rounded">Delete</a>
                            </td>
                            <td class="px-6 py-4">
                                <a href="#" class="px-4 py-1 text-sm text-white bg-red-400 rounded">More</a>
                            </td>
                        </tr>
                        </tbody>

                   
                </table>
                <Pagination n={2}/>
        </div>
    )
}