export default function AddAdvert(){
    return (
      <div className="GnFrM">
      <form className="FrmGeneral">
        <h1 className="FRMtX">Add Advertisment</h1>
        <label className="lblL">Advertisment Location</label>
        <select className="seLin">
          <option>Home top header</option>
          <option>News Left Side</option>
          <option>Bottom Banner</option>
          <option>Magazine Left Side</option>
          <option>Books Left Side</option>
        </select>
        <label className="lblL">Advertisment Title</label>
        <input type='text' className='INpX' placeholder='' name='' id='' />
        
        <label className="lblL">Photos/Video (.MP4 , .MOV , .AVI , .MKV , .WEBM , .FLV, .F4V, .SWF)</label>
        <input type='file' className='INpX' placeholder='' name='' id='' />
      
        <div className="SINP"><input type='submit' className="SubInp" value='Save'/></div> 
      </form>
      
    </div>
    )
}