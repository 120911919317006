import { useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import InfoCard from './pageComp/InfoCard';
export default function KinyOld(p){
    const [kinya,setKinya]=useState([]),[holder,setHolder]=useState({}),
    [current,setCurrent]=useState({});
    let current_date=new Date(),current_year=current_date.getFullYear();
    const [year,setyear]=useState(current_year);
    const setHolders=(npyear,kinys)=>{
    setHolder(values => ({...values, [npyear]:kinys}));
    }
    const _setKinya=(kinya)=>{
        let title= typeof kinya.yearx==='undefined' ? year : kinya.yearx;
        var imgs=typeof kinya?.bottom_title!=='undefined' ? <img src={kinya.cover} className='KinyImg iMgTop'/>:
          (kinya.in==false) ? 
           <p className='pXX'>{'Nta kinyamateka zibashije kuboneka mu mwaka wa '+title}</p> :
            <div className='dgDF'></div>;
    return <div onClick={e=>{p.showKinyP(kinya)}} className='w-full h-full'>
         <InfoCard content={'Kinyamateka Za Mbere '+title}/>
    <div className='KinyTop'>{imgs}</div>
   <div className='KinyBtM'>{kinya?.bottom_title}</div>
  </div>;
    }
    useEffect(()=>{
        setKinya(_setKinya({yearx:year,in:true}));
  fetch(p._lk+'kinyamatekalistdate/'+year,{
        method:'GET',headers:{'Content-Type': 'application/json'}})
        .then(res=>res.json()).then(ty=>{
         if(ty.length>0){
         setHolders(year,ty);setCurrent(ty[0]);setKinya(_setKinya(ty[0]));
         if(year==current_year){setKinya(_setKinya(ty[1]))}
         else{setKinya(_setKinya(ty[0]))}
        }else{setKinya(_setKinya({yearx:year,in:false}))}
        })
    },[year])

    useEffect(()=>{
    let _next_=document.querySelector('._next_'),
    _prev_=document.querySelector('._prev_');
     const changeY=(ty)=>{
        var nextPrevYear=year;
        var nextPrevYear=(ty==0) ? nextPrevYear+1 : nextPrevYear-1;
        nextPrevYear=(nextPrevYear>current_year) ? 1930 : 
               (nextPrevYear<1930) ? current_year : nextPrevYear;
        if(nextPrevYear!=year){setyear(nextPrevYear);}
     }
        const _nextPrev=(ty)=>{
        let holder_year=holder[year];
        if(Array.isArray(holder_year)){
         var currentIndex=holder_year.map((kn,i)=>{
        if(kn?.bottom_title==current?.bottom_title){return {kiny:kn,ix:i};}
         }).filter(ax=>typeof ax !=='undefined');
         

        if(currentIndex.length>0){
            var nextPrevIndex=currentIndex[0].ix;
            nextPrevIndex=(ty==0) ? nextPrevIndex-1 : nextPrevIndex+1;
            if(nextPrevIndex<0 || nextPrevIndex>=holder[year].length){changeY(ty)}
            else{
            setCurrent(holder[year][nextPrevIndex]);
            setKinya(_setKinya(holder[year][nextPrevIndex]));
            }
        }
    }
    else{changeY(ty)}
        }
    const next_=()=>{_nextPrev(1)},prev_=()=>{_nextPrev(0)}
    _next_.addEventListener('click',next_);
    _prev_.addEventListener('click',prev_);
    
    return ()=>{
    _next_.removeEventListener('click',next_);
    _prev_.removeEventListener('click',prev_);
    }
    },[current,year])

//
    return kinya;
}