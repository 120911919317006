import FeatherIcon from "feather-icons-react";
import WhattappIcon from "../pageComp/WhattappIcon";
import InfoCard from "../pageComp/InfoCard";
import NewRecent from "../pageComp/NewRecent";
import NewsVertical from "../pageComp/NewsVertical";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Books from "../Books";
export default function NewsLeft(p) {
  const menu_ = p.ty,
    subM_ = p.subM,
    _lk = p._lk,
    _type = menu_._type;
  const allM = typeof p.allM === "undefined" ? [] : p.allM;
  const ads = typeof p.ads === "undefined" ? [] : p.ads;
  let news1 = ads.filter((as) => as.location == "news1"),
    news2 = ads.filter((as) => as.location == "news2");
  const share = p.share;

  const [topnews, setTopNews] = useState([{}]);
  let linkX_, _title;
  if (menu_.id == 0) {
    linkX_ = "topnews/";
    _title = "Amakuru Agezweho";
  } else if (_type == "menu") {
    linkX_ = "newslist/" + menu_.id + "/";
    let sub = "";
    if ([2, 3, 5].includes(menu_.id)) {
      sub = " Amakuru y'";
    }
    _title = sub + menu_.title + " Agezweho";
  } else if (_type == "subM") {
    linkX_ = "newslistsub/" + subM_.id + "/";
    _title = menu_.title + " Agezweho " + subM_.title;
  }

  //playing audio if available

  useEffect(() => {
    fetch(_lk + linkX_, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((ty) => {
        if (ty.length == 0) {
          setTopNews([{}]);
        } else {
          let Ntitle = "",
            Xc = 0;
          if (ty.length > 0) {
            Ntitle = ty[0].summary.split("");
            if (Ntitle.length > 350) {
              Ntitle = Ntitle.slice(0, 350);
              Xc = 1;
            }
            Ntitle = Ntitle.join("");
            if (Xc == 1) {
              Ntitle = Ntitle + "...";
            }
            ty[0]._titl2 = Ntitle;
          }
          setTopNews(ty);
        }
      });
  }, [p]);

  useEffect(() => {
    var img = document.querySelector(".XmgTR");
    if (img === null) {
    } else {
      img.src = img.dataset.src;
      img.onload = () => {
        img.parentElement.classList.remove("VdfrXS");
        img.classList.remove("invisible");
      };
      img.onerror = () => {
        img.classList.add("invisible");
      };
    }
  });

  //play topnews audio.
  const [playIcnX, setiCNN] = useState("play");
  useEffect(() => {
    var audioXx = document.querySelector("._jhsUT");
    function play1() {
      var audiox = document.querySelector(".audio_CCXx");
      if (audiox.paused) {
        audiox.play();
        setiCNN("pause");
      } else {
        audiox.pause();
        setiCNN("play");
      }
    }

    if (audioXx != null) {
      audioXx.addEventListener("click", play1);
      return () => {
        audioXx.removeEventListener("click", play1);
      };
    }
  }, [topnews]);

  let fbShare =
      share.facebook +
      share.base_Link +
      "#/" +
      menu_.link +
      "/" +
      topnews[0].id,
    twitterShare =
      share.twitter +
      encodeURIComponent(
        share.base_Link + "#/" + menu_.link + "/" + topnews[0].id
      ) +
      "&&text=" +
      encodeURIComponent(topnews[0].title),
    whattapp =
      share.whattapp +
      encodeURIComponent(
        topnews[0].title +
          " " +
          share.base_Link +
          "#/" +
          menu_.link +
          "/" +
          topnews[0].id
      );

  return (
    <div className="NxLfrX">
      <div className="TpNlXpYCu sLgf">
        <Helmet>
          <title>{menu_.title}</title>
          <meta property="og:title" content={menu_.title} />
          <meta property="og:image" content={topnews[0].main_photo} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:description" content={topnews[0].summary} />
        </Helmet>
        <div className="NYnhd">
          <div className="topTVCF-Slider">
            <InfoCard content={_title} />
            <div className="Innerw VdfrXS">
              <img
                className="iMgTop invisible XmgTR"
                data-src={topnews[0].main_photo}
              ></img>
            </div>
          </div>

          <div className="btmYVdg-t">
            {"audio_news" in topnews[0] && topnews[0].audio_news.length > 0 && (
              <div className="divAuxXD">
                <span className="AudioButton _jhsUT">
                  <FeatherIcon className="XccX" icon={playIcnX} />
                </span>
                <audio
                  className="audio_CCXx"
                  src={topnews[0].audio_news[0].file}
                ></audio>
              </div>
            )}
            <Link
              className="_cliCds"
              to={"/" + menu_.link + "/" + topnews[0].id}
              state={topnews[0]}
              href="#"
            >
              <p className="spNNTtl">{topnews[0]._titl2}</p>
            </Link>
            <div className="ActinDVX">
              <a target="_blank" href={fbShare} className="SocXl scxl fcBk">
                <FeatherIcon height="14" width="14" icon="facebook" />
              </a>
              <a target="_blank" href={twitterShare} className="SocXl tWITTE">
                <FeatherIcon height="14" width="14" icon="twitter" />
              </a>
              <a
                target="_blank"
                href={whattapp}
                data-action="share/whatsapp/share"
                className="SocXl whatps"
              >
                <WhattappIcon height="14px" width="14px" fill="white" />
              </a>
            </div>
          </div>
        </div>

        <NewsVertical share={share} data={menu_} news={topnews} />
      </div>

      {menu_.id == 0 && allM.length > 0 && (
        <div className="RecTnEW sLgf">
          <NewRecent tt="HM" share={share} _lk={_lk} typ={allM[0]} />
          <NewRecent tt="HM" share={share} _lk={_lk} typ={allM[2]} />
          <div className="_AdvertSmX1 ">
            {news1.length > 0 ? (
              <img
                data-src={news1[0].file_document}
                src={news1[0].file_document}
                className="imgXXXVV"
              />
            ) : (
              p.ptext
            )}
          </div>
          <NewRecent tt="HM" share={share} _lk={_lk} typ={allM[3]} />
          <NewRecent tt="HM" share={share} _lk={_lk} typ={allM[4]} />
          <div className="_AdvertSmX1">
            {news2.length > 0 ? (
              <img src={news2[0].file_document} className="imgXXXVV" />
            ) : (
              p.ptext
            )}
          </div>
          <div className="_booksDivsio">
            <p className="_bNkssd3">Ibitabo Bigezweho</p>
            <Books _lk={p._lk} type="bk" limit="8" claS="XcnyNs" />
          </div>
        </div>
      )}
      {menu_.id > 0 && (
        <div className="RecTnEW sLgf">
          <NewRecent
            tt="SG"
            share={share}
            news={topnews}
            subM={subM_}
            _lk={_lk}
            typ={menu_}
          />
        </div>
      )}
    </div>
  );
}

/*













<span className='ContaiSpN hidden'>
         <span className='Nvyx'>0</span>
         <span className='NbvyX'>
     <FeatherIcon height='16' width='16' icon='message-circle'/>
         </span>
         </span>
 
         <span className='ContaiSpN hidden'>
         <span className='Nvyx BVGHx'> 
         <FeatherIcon height='12' width='12' icon='heart'/><p>Like </p></span>
         <span className='NbvyX Nwht'>0</span>
         </span>*/
