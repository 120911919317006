import FeatherIcon from 'feather-icons-react';
import { useState } from 'react';
export function NewsVideo(p){

    return (
<div className='VxcY videoP'>
    <span className='xCNYC' onClick={p.h}><FeatherIcon height='20' width='20' icon='arrow-left' /></span>
      <form className="FrmGeneral">
      <h1 className="FRMtX">Add a video Before paragraph {p.np}</h1>

      <label className="lblL">Video Title</label>
        <input type='text' className='INpX' placeholder='' name='' id='' />
       

      <label className="lblL">News Video youtube Link</label>
      <input type='text' className='INpX' placeholder='' name='' id='' />
       
      <div className="SINP"><input type='submit' className="SubInp" value='Save video'/></div> 
    
      </form>
        </div> 
    )
}

export function NewsAudio(p){
    return (

<div className='VxcY AUDIOP'>
<span className='xCNYC' onClick={p.h}><FeatherIcon height='20' width='20' icon='arrow-left' /></span>
    
  <form className="FrmGeneral">
      <h1 className="FRMtX">Add  audio before paragraph {p.np}</h1>

      <label className="lblL">Audio title/name</label>
      <input type='text' className='INpX' placeholder='' name='' id='' />
       

      <label className="lblL">News audio file (.mp3 ,.M4A , .flac , .wav)</label>
      <input type='file' className='INpX' placeholder='' name='' id='' />
       
      <div className="SINP"><input type='submit' className="SubInp" value='Save audio'/></div> 
    
      </form>
  </div>


    )
} 


export function NewsPhoto(p){
    const [file,isFile]=useState();
    const [Picked,isPicked]=useState(false);
    const changeHandler = (event) => {
		isFile(event.target.files[0]);
		isPicked(true);
	};
    const handleSubmission = (event) => {
        
    }
    return (
<div className='VxcY PhotoP'>
<span className='xCNYC' onClick={p.h}><FeatherIcon height='20' width='20' icon='arrow-left' /></span>
    
<form className="FrmGeneral">
    <h1 className="FRMtX">Add a picture before paragraph {p.np}</h1>

    <label className="lblL">Photo bottom title</label>
    <input type='text' className='INpX' placeholder='' name='' id='' />
     

    <label className="lblL">News photo (.png ,.jpeg , etc)</label>
    <input type='file'  onChange={changeHandler} className='INpX' placeholder='' name='' id='' />
     
     
     { Picked ? (
         <div className='ImagePreview'>
    <p>Filename: {file.name}</p>
					<p>Filetype: {file.type}</p>
					<p>Size in bytes: {file.size}</p>
					<p>
						lastModifiedDate:{' '}
						{file.lastModifiedDate.toLocaleDateString()}
					</p>         

         </div>
     )
      :(<div></div>)

     }
    

    <div className="SINP"><input onClick={handleSubmission} type='submit' className="SubInp" value='Save photo'/></div> 
    
    </form>

</div>
    )
}


