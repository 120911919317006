import { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import NewsP from './NewsP';
export default function AddNews(){
  //<NewsP x={hlder} />

  const [inputs,setInputs]=useState();
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  } 
 
   const [nP,np2]=useState(1); const AddNp=()=>np2(nP+1);
   const [addP,isAdP]=useState([]);
   const setAdP=()=>{
     AddNp();isAdP([...addP,<NewsP np={nP}/>])
    };

    const handleSubmit=(e)=>{
      e.preventDefault();
     let form=document.forms.fmKiny;
     let data=new FormData(form);
     for(var pair of data.entries()){
      console.log(pair[0], pair[1]);
     }
    }
    
    return (
      <div className="GnFrM">
      <form className="FrmGeneral GrM2" name='fmKiny'>
      <div className=" DcX1 w-[60%]  self-start rounded-md  flex flex-col p-5 mr-1 bg-white">
      <h1 className="FRMtX">Register new  news 
      
      </h1>
      {addP.map((m,i)=>m)}
      <div className='CBVG flex flex-row' onClick={setAdP}>
      <span className='SPnc bg-blue-900 text-white' ><FeatherIcon height='20' width='20' icon='plus' /></span>
       <span className='Xnyx bg-red-200 flex justify-center items-center scale-15 cursor-pointer p-[10px] top-0  left-full text-[12px] w-[120px] rounded-2xl'>Add Paragraph</span>
      </div>
          </div>
    <div className="w-[40%] rounded-md self-start flex flex-col p-5 bg-white">
        
    
        <label className="lblL">News Title</label>
        <textarea name='newsTitle' className='TextAr AR1'></textarea>
       
        <label className="lblL">News Summary</label>
        <textarea name='summary' className='TextAr'></textarea>
        





        <label className="lblL">News Category</label>
        <select name='newsType' className="seLin">
              <option option={1}>Amakuru ya Kiliziya</option>
              <option option={2}>Amakuru Anyuranye</option>
              <option option={3}>Amakuru y’Uburezi N’umuco</option>
              <option option={4}>Amakuru y’ubuzima</option>
              <option option={5}>Amakuru y’imikino ni myidagaduro</option>
              <option option={6}>Soma wunve uraseka</option>
            </select>

        <label className="lblL">Related Kinyamateka (Optional)</label>
        <select name='relatedKim' className="seLin">
              <option>02/2022</option>
              <option>01/2022</option>
              <option>12/2021</option>
              <option>11/2021</option>
              <option>10/2021</option>
              <option>09/2021</option>
            </select>
          
        
        
        <label className="lblL">Kinyamateka page Number</label>
        <input type='number' className='INpX uppercase' placeholder='' name='kinyPgN' id='' />
       
        <label className="lblL">Journalist</label>
        <input type='text' className='INpX uppercase' placeholder='' name='Editor' id='' />
        

        <div className="SINP"><input type='submit' onClick={handleSubmit} className="SubInp" value='Save'/></div> 
        </div>
        
      </form>
     
     
    </div>
    )
}