import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import Audio from "./Audio";
import { useEffect } from "react";

export default function Menus(p) {
  let lsx,
    objX = p.menus;
  lsx = objX.map((menu) => {
    var bX = menu.submenu_menu.length > 0 ? 1 : 0;
    var sx = menu.submenu_menu.map((vb) => {
      var lks;
      if (menu.id > 0 && menu.id <= 5) {
        lks = (
          <Link className="LInkX" to={"/" + vb.link}>
            {vb.title}
          </Link>
        );
      }
      if (menu.id == 8) {
        bX = 0;
      } else {
        lks = (
          <Link className="LInkX" to={"/" + vb.link}>
            {vb.title}
          </Link>
        );
        return (
          <li key={vb.id} className="sUBlI">
            {lks}
          </li>
        );
      }
    });

    let link;
    if (menu.id > 0 && menu.id <= 5) {
      link = (
        <Link className="LInkX" to={"/" + menu.link}>
          {menu.title}
        </Link>
      );
    } else if (menu.id == 7) {
      link = (
        <a
          className="LInkX"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {menu.title}
        </a>
      );
    } else if (menu.id == 8) {
      link = (
        <a className="LInkX LinXAuDio">
          <span className="plyhd">
            <FeatherIcon height="16" width="16" icon="volume-2" />
          </span>
          {menu.title}
        </a>
      );
    } else {
      link = (
        <Link className="LInkX" to={"/" + menu.link}>
          {menu.title}
        </Link>
      );
    }
    let _class = "_newsXc";
    if (menu.id == 7 || menu.id == 8) {
      _class = "";
    }

    return (
      <li key={menu.id} className={"MainL " + _class}>
        {link}
        {bX > 0 && <ul className="navGUL">{sx}</ul>}
      </li>
    );
  });

  useEffect(() => {
    var menuLs = document.querySelectorAll("._newsXc");
    menuLs = Array.from(menuLs);
    menuLs.forEach((mnu) => {
      mnu.addEventListener("click", (e) => {
        menuLs.forEach((mx) => {
          mx.classList.remove("_Xmnyxd");
        });
        mnu.classList.add("_Xmnyxd");
      });
    });
    var dcV = document.querySelector(".LinXAuDio");
    const showAudiosS = () => {
      var dcv = document.querySelector(".AudX");
      if (dcv == null) {
        p.audioS();
      } else {
        dcv.classList.remove("HdX");
      }
    };
    if (dcV !== null) {
      dcV.addEventListener("click", showAudiosS);
    }
    return () => {
      if (dcV !== null) {
        dcV.removeEventListener("click", showAudiosS);
      }
    };
  }, [objX]);

  return (
    <div className="MinNav">
      <ul className="NavDIV">
        <li className="MainL _newsXc">
          <Link className="LInkX" to={"/amakuru"}>
            Amakuru
          </Link>
        </li>
        {lsx}{" "}
      </ul>
      {p.c && (
        <Audio
          setNewsP={p.setNewsP}
          subDv={p.subDv}
          _lk={p._lk}
          hideA={p.audioH}
          ty={objX[7]}
        ></Audio>
      )}
    </div>
  );
}
