import { useCallback, useEffect, useState } from "react";
import LoginContainer from "./LoginContainer";
import http from "../../config/axiousBase";

export default function SubscriberSignUp(p) {
  let values = {
    password: "",
    password2: "",
    username: "kiny133",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    parish: "",
  };
  const [upValue, setvalues] = useState(values),
    [loading, setLoading] = useState(false),
    [success, setSuccess] = useState(false),
    [errorMessage, setErrorMessage] = useState(""),
    [error, setError] = useState(false),
    [valid, setValid] = useState(false),
    [login, goLogin] = useState(),
    /**
     * -------------------------------------------------------------------
     * Handle validation
     * ----------------------------------------------------------------------------
     */
    handleValidation = (obj) => {
      for (var key in obj) {
        console.log("key", key);
        if (obj[key] == "") {
          const msg = key + " yawe iracyenewe";
          setErrorMessage(msg);
          setValid(true);
          return false;
        }
      }
      return true;
    },
    /**
     * -------------------------------------------------------------------
     * Handle user inputs change
     * ----------------------------------------------------------------------------
     */
    handleChange = (e) => {
      setError(false);
      setValid(false);

      const name = e.target.name,
        value = e.target.value;
      setvalues((values) => ({ ...values, [name]: value }));
    },
    handleEmailValidation = (email) => {
      const regex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (!email || regex.test(email) === false) {
        return false;
      }
      return true;
    },
    /**
     * -------------------------------------------------------------------
     * User registration
     * ----------------------------------------------------------------------------
     */
    handleSubmit = async (e) => {
      e.preventDefault();
      setValid(false);
      setError(false);

      if (!handleValidation(upValue)) {
        return;
      }

      if (upValue.password !== upValue.password2) {
        setErrorMessage("Passward igomba kuba isa nkiyakabiri");
        setValid(true);
        return;
      } else if (upValue.password.length < 8) {
        setValid(true);
        setErrorMessage(
          "Passward igomba kuba irihejuru cyagwa ingana na characters 8"
        );
        setValid(true);
        return;
      } else if (!handleEmailValidation(upValue.email)) {
        setErrorMessage("Emeri yawe ntago yemewe, ongera uyandike neza");
        setValid(true);
        return;
      }
      try {
        setLoading(true);
        const endpoint = "client/";
        const response = await http.post(endpoint, upValue);
        if (response) {
          setLoading(false);
          setSuccess(true);
          //redirect user to login page
          setTimeout(() => {
            setSuccess(false);
            goLogin(p.lgnS);
          }, 1500);
        }
      } catch (error) {
        setLoading(false);
        setError(true);
        setSuccess(false);
        console.log("error", error);
      }
    };

  return (
    <LoginContainer p={p} vl="sign">
      <form className="">
        <div className="InCont">
          <h1 className="lgn-titl text-primary">
            Konti nshya y'umunyamuryango
          </h1>
        </div>

        <div className="InCont">
          <label>Izina ryambere</label>
          <input
            autoComplete="off"
            name="first_name"
            value={upValue.first_name}
            onChange={handleChange}
            maxLength={40}
            type="text"
            placeholder="izina ryambere"
            className="form-control mb-2 p-2 mt-1"
          />
        </div>

        <div className="InCont">
          <label>Irindi zina</label>
          <input
            autoComplete="off"
            name="last_name"
            value={upValue.last_name}
            onChange={handleChange}
            maxLength={40}
            type="text"
            className="form-control mb-2 p-2 mt-1"
            placeholder="Irindi Zina"
          />
        </div>

        <div className="InCont">
          <label>Numero ya telefone</label>
          <input
            autoComplete="off"
            name="phone"
            value={upValue.phone}
            onChange={handleChange}
            maxLength={40}
            type="text"
            className="form-control mb-2 p-2 mt-1"
            placeholder="Numero ya telephone"
          />
        </div>

        <div className="InCont">
          <label>Emeyili</label>
          <input
            autoComplete="off"
            value={upValue.email}
            onChange={handleChange}
            name="email"
            maxLength={40}
            type="text"
            className="form-control mb-2 p-2 mt-1"
            placeholder="Email address"
          />
        </div>

        <div className="InCont">
          <label>Ijambobanga</label>
          <input
            autoComplete="off"
            name="password"
            value={upValue.password}
            onChange={handleChange}
            type="password"
            maxLength={16}
            className="form-control mb-2 p-2 mt-1"
            placeholder="ijambobanga"
          />
        </div>

        <div className="InCont">
          <label>Emeza Ijambobanga</label>

          <input
            autoComplete="off"
            name="password2"
            value={upValue.password2}
            onChange={handleChange}
            type="password"
            maxLength={16}
            className="form-control mb-2 p-2 mt-1"
            placeholder="Emeza Ijambobanga"
          />
        </div>

        <div className="InCont">
          <label>Aho Utuye</label>
          <input
            autoComplete="off"
            id="email"
            name="address"
            value={upValue.address}
            onChange={handleChange}
            maxLength={40}
            type="text"
            className="form-control mb-2 p-2 mt-1"
            placeholder="Aho Ubarizwa"
          />
        </div>

        <div className="InCont mb-3">
          <label>Paruwasi</label>
          <input
            autoComplete="off"
            name="parish"
            value={upValue.parish}
            onChange={handleChange}
            maxLength={40}
            type="text"
            className="form-control mb-2 p-2 mt-1"
            placeholder="Paruwasi"
          />
        </div>

        {success && (
          <div class="alert alert-success" role="alert">
            Wabashije kwiyandikisha neza, Murakoze.
          </div>
        )}

        {error && (
          <div class="alert alert-danger" role="alert">
            Kwiyandikisha ntago byakunze, mwongere mukanya
          </div>
        )}

        {valid && (
          <div class="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}

        <div className="InCont mt-8">
          {!loading && !success && (
            <button className="btn btn-primary" onClick={handleSubmit}>
              Kora konti
            </button>
          )}

          <div
            className="flex items-center justify-center w-full"
            role="status"
          >
            {loading && (
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </div>

          <div className="BvyNB flex items-center flex-col justify-center w-full relative pt-2">
            <button
              onClick={p.lgnS}
              className="BtnNS mt-1 text-[#E703F9] pb-4 font-light"
              type="button"
            >
              Usanzwe ufite konti? Injira
            </button>
          </div>
        </div>
      </form>
    </LoginContainer>
  );
}
