import Pagination from "./Pagination";

export default function AdAds(){
    return (
      <div className="KinYX w-[90%] relative left-[5%]">
      <p className="w-full p-5 text-[20px] font-semibold">Advertisment List</p>
      <table className="w-full">
                 <thead class="bg-gray-50">
                     <tr>
                         <th class="px-6 py-2 text-xs text-gray-500">
                             ID
                         </th>
                         <th class="px-6 py-2 text-xs text-gray-500">
                            Audio Title
                         </th>
                         <th class="px-6 py-2 text-xs text-gray-500">
                            File Name
                         </th>
                         <th class="px-6 py-2 text-xs text-gray-500">
                          Location  
                         </th>
                         <th class="px-6 py-2 text-xs text-gray-500">
                             Edit
                         </th>
                         <th class="px-6 py-2 text-xs text-gray-500">
                             Delete
                         </th>
                         <th class="px-6 py-2 text-xs text-gray-500">
                             Details
                         </th>
                     </tr>
                 </thead>
                
             </table>
     <Pagination n={1}/>
     </div>
    )
}